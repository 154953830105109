#container-productVip {
	.articleBoxWithSideBox {
		padding: 88px 112px 116px 112px;
		min-height: 752px;

		.contain-myProduct_description {
			margin-bottom: 80px;
		}
	}

	.card-title {
		font-size: 16px;
		.card-text {
			font-size: 14px;
		}
	}

	button {
		font-size: 14px;
	}
	.section-add {
		margin-bottom: 16px;
		min-height: 40px;
	}

	.contain-myProduct_title {
		font-size: 32px;
		font-weight: bold;
	}

	.addVipBtn {
		height: 40px;
		min-width: 112px;
		font-size: 14px;
	}

	.product {
		background-color: #daeeff;
		padding: 5%;
	}

	.product-picture {
		text-align: center;
		margin-bottom: 48px;
	}

	.product-picture img {
		width: 161px;
		height: 161px;
	}

	.product-info-title {
		font-size: 20px;
		font-weight: bold;
	}

	.product-info-data {
		font-size: 20px;
	}

	.alarm {
		color: red;
	}

	.input-group {
		display: flex;
	}

	.phone-group {
		display: flex;
	}

	.emailID-group {
		display: flex;
	}

	.productVip__titile {
		margin-bottom: 10%;
	}

	.table-thead {
		background: #f4f4f4;
	}

	.friendAvatar {
		height: 48px;
		width: 48px;
		border: 1px solid white;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		overflow: hidden;
	}

	.friendAvatar img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.isAccepted {
		color: #989898;
	}

	.align-middle {
		width: 25%;
	}

	.btnText {
		color: white;
	}

	.inner-container {
		position: relative;
	}

	.table__title-rwd {
		display: none !important;
	}

	.vip-info {
		p {
			// padding-top: 32px;
		}
	}

	tbody {
		border-top: none;

		tr {
			border: 1px solid #dadada;
			border-top: none;
		}
	}

	th {
		color: #282828;
	}

	.no-frd-div {
		// margin: 150px auto 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.cancel__svg {
		cursor: pointer;

		.MuiSvgIcon-root {
			border: 1px solid #dadada;
			font-size: 24px;
			color: #989898;
		}

		&:hover .MuiSvgIcon-root {
			color: #282828;
		}
	}

	.group__svg {
		height: 96px;
		width: 96px;
	}

	.group__svg > .MuiSvgIcon-root {
		background-color: #f4f4f4;
		width: 96px;
		height: 96px;
		color: #1278e8;
		border: 16px solid #f4f4f4;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	.img__wrapper {
		width: 96px;
		height: 96px;
		border-radius: 50%;
		border: 1px solid white;
		overflow: hidden;
		margin: 0 auto;
		margin-bottom: 2rem;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.vip-info .card {
		width: 18rem;
		margin: 150px auto 0 auto;
		text-align: center;
		border: 0;
	}

	.card-text {
		font-size: 14px;
	}

	.section-footer {
		button {
			max-width: 88px;
			font-size: 14px;
			box-shadow: none;
		}
	}

	// Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 992px and also iPads specifically.
	@media only screen and (max-width: 992px) {
		.articleBoxWithSideBox {
			padding: 88px 16px 44px 16px;
			margin-bottom: 32px;
			min-height: auto;
			border: none;
			border-radius: 0 0 8px 8px;
			-webkit-border-radius: 0 0 8px 8px;
			-moz-border-radius: 0 0 8px 8px;
			-ms-border-radius: 0 0 8px 8px;
			-o-border-radius: 0 0 8px 8px;
			font-size: 12px;
		}

		.containTitle {
			left: 16px;
			font-size: 16px;
		}

		tbody {
			border-top: none;

			tr td.align-middle {
				width: auto;
			}
		}

		.table__tr {
			display: none;
		}

		.table__title-rwd {
			display: block !important;
			background: #f4f4f4;
			border: none;
			border-bottom: 1px solid #dadada;
			padding: 16px;
		}

		.no-frd-div {
			margin: 0 auto 32px auto;

			.card-text {
				font-size: 14px;
			}
		}

		.section-footer {
			margin-bottom: 32px;
		}

		.vip-info .card {
			margin: 0 auto 0 auto;
		}

		.friendAvatar {
			height: 24px;
			width: 24px;
			object-fit: cover;
		}

		.cancel__svg > .MuiSvgIcon-root {
			font-size: 16px;
		}

		.text-content {
			font-size: 12px;
		}

		.articleBoxWithSideBox tbody tr {
			border: none;
			border-bottom: 1px solid #dadada;
		}
	}
}
