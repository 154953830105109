#container-presetDrill {
	.presetDrillWrapper .bg-white {
		min-height: 753px;
	}

	p {
		font-size: 14px;
	}

	th, td {
		cursor: pointer;
		padding: 0.5rem !important;
	}

	.presetDrillLeftWrapper {
		.titleWrapper {
			border-bottom: 1px solid var(--grey1);

			.btnWrapper .btn {
				width: 32px;
				height: 32px;
			}
		}

		.presetDrillGroupWrapper {
			height: 40px;
			.text-danger {
				color: #f01b47 !important;
			}
			cursor: pointer;

			&:hover,
			&.active {
				background-color: #ecf4fd;
			}

			.presetDrillBtnGroupWrapper {
				min-width: 36px;
			}
		}

		.groupName {
			white-space: nowrap;
			overflow: hidden;
		}
	}

	.presetDrillRightWrapper {
		th td {
			text-align: left;
		}
	}

	.table {
		tbody {	
			tr {
				--bs-table-active-bg: none !important;
				--bs-table-hover-bg: none !important;
			}
		
			tr {
				&:hover,
				&.active {
					background-color: #ecf4fd !important;
				}
			}
		}
	}

}
