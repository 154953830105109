.accountDelete-container-class {
    .accountDelete-popup-class {
        width: 496px;
        height: 496px;
    }

    .accountDelete-confirm-button-class {
        font-size: 14px;
        background-color: #CC3300 !important;
        border-color: #989898 !important;
        width: 88px;
        height: 40px;
    
        &:hover {
            background-color: var(--grey4)!important;
            border-color: #707070 !important;
    
        }
    }
    
    .accountDelete-cancel-button-class {
        font-size: 14px;
        color: var(--grey5);
        background-color: #ffffff !important;
        border-color: #707070 !important;
        width: 88px;
        height: 40px;
        margin-right: 16px;
    
        &:hover {
            border-color: var(--grey5) !important;
            color: var(--grey5);
    
        }
    }
}

#container-accountDelete {
	.articleBoxWithSideBox {
        min-height: 312px;

        
        .containTitle {
            left: 40px;
        }

		label {
			font-size: 14px;
		}

		#oldPWD,
		#newPWD,
		#confirmPWD {
			font-size: 16px;
			height: 48px;
		}

		p {
			font-size: 14px;
		}

		button {
			max-width: 216px;
			// width: 100%;
			height: 48px;
			font-size: 14px;
		}
	}

	#btn-confirm {
		margin-top: 32px;
		max-width: 216px;
		width: 100%;
		height: 48px;
		font-size: 14px;
	}

}

@media only screen and (max-width: 992px) {
    .accountDelete-container-class {
        .accountDelete-popup-class {
            width: 344px;
            height: 328px;
        }
    }

	#container-accountDelete {
		.articleBoxWithSideBox {
			padding: 70px 16px 16px 16px;
			border-radius: 8px;
            border: 1px solid #DADADA;
            font-size: 16px;
        
            .containTitle {
                position: absolute;
                top: 32px;
                font-size: 16px;
                left: 16px;

                .row {     
                    margin-top: 8px;
                }
            }


            .warningWord1 {
                font-size: 12px;
            }

            .warningWord2 {
                margin-top: 0;
                font-size: 12px;
            }
		}
	}
	#btn-confirm {
		margin-bottom: 48px;
	}
}
