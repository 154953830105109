#container-downloadCenterUpdate {
	.searchWrapper {
		button:nth-of-type(1) {
			&:hover {
				color: white;
			}
		}
	}

	.searchTableWrapper {
		.searchTableTop {
			button a {
				color: white;
			}

			span {
				cursor: pointer;
			}
		}
	}
}
