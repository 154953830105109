#container-userUpdateDetail {
	input:not(#getOfficialMSG) ,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}

	select{
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	textarea {
		height: 110px;
		resize: none;
		box-shadow: none;
	}

	.btnCancel {
		&:hover {
			color: white;
		}
	}

	.manageFileWrapper {
		button:nth-of-type(1) {
			&:hover {
				color: white;
			}
		}

		.fileView {
			height: 32px;
		}

		.cancelFileBtn {
			cursor: pointer;
		}
	}

	.infoWrapper {
		.infoTop {
			span {
				cursor: pointer;
			}
		}
	}

	.photo-wrapper {
		.photoInfo {
			padding: 2rem;

			.outer_container {
				width: 180px;
				height: 180px;
				border-radius: 50%;
				overflow: hidden;
				margin-bottom: 2rem;
			}

			.inner_container {
				margin: 0px auto;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}

			.photoBtnWrapper {
				width: 180px;
			}
		}

		.btn {
			height: 32px;
			width: 128px;
			padding: 4px;
		}
	}
}
