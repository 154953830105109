#container-authUpdateEdit {
	th {
		cursor: pointer;
	}

	input:disabled {
		background-color: var(--grey1);
	}

	.tableWrapper {
		height: 460px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: var(--grey1);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--grey3);
		/*border-radius: 6px;*/
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: var(--grey4);
	}

	.submitBtnWrapper {
		.btn {
			width: 88px;
			height: 32px;
			line-height: 0;
		}

		.btn-outline-primary:hover {
			color: white;
		}
	}

	.memberPageWrapper {
		.buttonWrapper {
			.btn-primary {
				width: 112px;
				height: 32px;
			}

			.btn-outline-danger {
				width: 88px;
				height: 32px;
				line-height: 0;
				color: #f01b47 !important;
				border-color: #f01b47 !important;

				&:hover {
					background-color: #f01b47 !important;
					color: white !important;
				}
			}
		}

		.memberDeleteCheckboxWrapper {
			width: 48px;

			label {
				cursor: pointer;
			}
		}
	}

	.authUpdateWrapper {
		.bg-white {
			min-height: 540px;
		}

		thead th label {
			cursor: pointer;
		}
		tbody {
			tr {
				--bs-table-hover-bg: rgba(236, 244, 253, 1) !important;
				&:hover,
				&.active {
					background-color: rgba(236, 244, 253, 1) !important;
				}

				label {
					cursor: pointer;
				}
			}
		}
	}
}
