#container-supportQuestion {
	.supportQuestion-section {
		&.section2 {
			min-height: auto;
			padding-block: 56px;
			margin-top: 120px;

			h2 {
				font-size: 36px;
			}

			h6 {
				font-size: 16px;
				max-width: 1168px;
			}
		}

		&.section3 {
			max-width: 1096px;

			img {
				pointer-events: none;
			}

			.faqTitle {
				height: 56px;
				cursor: pointer;
			}

			.faqSecondQuestion {
				cursor: pointer;
				font-size: 16px;
			}

			.faqSecondAnswer {
				font-size: 14px;
			}

			span.textActive {
				background-color: var(--warning);
			}

			.collapseWrapper button {
				font-size: 14px;
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-supportQuestion {
		.supportQuestion-section {
			&.section2 {
				padding-top: 30px;
				padding-bottom: 64px;
				margin-top: 258px;

				h2 {
					font-size: 24px;
				}

				h6 {
					font-size: 14px;
				}
			}

			&.section3 {
				.faqSecondQuestion {
					font-size: 14;
				}
			}
		}
	}
}
