#container-userDrill {
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}

	select {
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.searchWrapper {
		.searchInput {
			width: 320px;
		}

		button {
			height: 40px;
		}

		.btn-outline-primary:hover {
			color: #fff;
		}
	}

	.UserDrillListTable th {
		cursor: pointer;
	}
}
