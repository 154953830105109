#container-explore {
	background-color: #f8f8f8;

	.exploreTop-section {
		margin-top: 80px;
		background-image: url('../../../public/images/explore/exploreTop-section1-1_web.png');
		height: calc(100vh - 256px);
		min-height: 648 * 0.85px;
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;

		h1 {
			font-size: 50 * 0.85px;
			margin-top: 176px;
			margin-bottom: 24px;
			color: #ffffff;
		}
		p {
			font-size: 20 * 0.85px;
			color: #ffffff;
			margin-bottom: 6%;
		}

		.searchWrapContainer {
			display: block;
		}

		.searchWrap {
			width: 872px;

			input {
				height: 64 * 0.85px;
				text-indent: 20 * 0.85px;
				font-size: 20 * 0.85px;
				outline: none;

				&::placeholder {
					color: var(--grey3);
				}
			}

			img {
				right: 32 * 0.85px;
				top: 20 * 0.85px;
				cursor: pointer;
			}
		}

		.pageBtnWrapper {
			max-width: 1096 * 0.85px;
			bottom: -70 * 0.85px;
			height: 136 * 0.85px;
			border-radius: 30 * 0.85px;
			left: 50%;
			transform: translateX(-50%);

			h2 {
				font-size: 24 * 0.85px;
			}

			a {
				color: var(--grey5);
			}

			.productRegisterBtn {
				cursor: pointer;
			}

			.btn-primary {
				color: white;
				background-color: #1278e8;
				//border-color: #707070;
				height: 48px;
				width: 144px;
				font-size: 16 * 0.85px;
				border-radius: 12px;
				-webkit-border-radius: 12px;
				-moz-border-radius: 12px;
				-ms-border-radius: 12px;
				-o-border-radius: 12px;
			}

			.btn-primary.active:hover {
				background-color: #368ceb;
				// border-color: #2a86ea;
			}

			.btn-primary:not(.active) {
				background-color: transparent;
				border: none;
				color: #282828;
			}

			.btn-primary:not(.active):hover {
				color: white;
				background-color: #1278e8;
				border-color: #707070;
			}

			.classBtnInnerWrapper {
				max-width: 624px;
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.explore-section {
		&.section2 {
			min-height: auto;
			// padding-block: 56px;
			margin-top: 120px;
			margin-bottom: 80px;
			padding-top: 24px;

			h2 {
				font-size: 36px;
			}

			h6 {
				font-size: 16px;
				max-width: 1168px;
			}
		}

		&.section3 {
			// max-width: 1096px;
			min-height: 360px;

			.cardArea {
				max-width: 1320px;
				margin: 0 auto;
				padding: 0;
			}

			.card {
				width: 424px;
				height: 360px;
				padding: 0 12px;
				margin-bottom: 24px;
				background-color: transparent;
				border: none;

				&:last-child:nth-last-child(2) {
					margin-right: auto;
				}
			}

			.cardImg {
				height: 264px;
				border-top-left-radius: 24px;
				border-top-right-radius: 24px;
				overflow: hidden;
			}

			.cardWords {
				height: 96px;
				margin-left: 0;
				margin-right: 0;
				border-bottom-left-radius: 24px;
				border-bottom-right-radius: 24px;

				.cardDesc {
					padding-left: 32px;
				}

				.cardTitle {
					font-size: 20 * 0.85px;
				}

				.cardCategory {
					font-size: 14 * 0.85px;
				}

				.cardVideoLinkContainer {
					height: 32px;
					border-left: 1px solid #989898;
				}

				.cardVideoLink {
					cursor: pointer;
					padding: 8px 0 0 32px;
					line-height: 1;

					a {
						color: #282828;
						line-height: 1;
					}

					.arrowImg {
						height: 16px;
						width: 8px;
						cursor: pointer;
						background-image: url('../../../public/images/explore/arrowNext.png');
					}

					&:hover {
						a {
							color: #1278e8;
						}

						img {
							color: #1278e8;
						}

						.arrowImg {
							background-image: url('../../../public/images/explore/arrowNextHover.png');
						}
					}
				}
			}

			.faqTitle {
				height: 56px;
				cursor: pointer;
			}

			.faqSecondQuestion {
				cursor: pointer;
				font-size: 16px;
			}

			.faqSecondAnswer {
				font-size: 14px;
			}

			span.textActive {
				background-color: var(--warning);
			}

			.collapseWrapper button {
				font-size: 14px;
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-explore {
		.exploreTop-section {
			margin-top: 48px;
			background-image: url('../../../public/images/explore/exploreTop-section1-1_app.png');
			max-height: 500px;
			min-height: auto;
			background-size: cover;

			h1 {
				font-size: 24 * 0.85px;
				margin-top: 40 * 0.85px;
				margin-bottom: 8 * 0.85px;
			}
			p {
				font-size: 16 * 0.85px;
				margin-bottom: 32 * 0.85px;
			}

			.searchWrapContainer {
				position: absolute;
				bottom: 36px;
			}

			.searchWrap {
				width: 100%;
				padding: 0 24px 0 24px;
				margin-top: 85%;

				input {
					height: 40 * 0.85px;
					font-size: 16 * 0.85px;
				}

				img {
					right: 48 * 0.85px;
					top: 6 * 0.85px;
					cursor: pointer;
				}
			}

			.pageBtnWrapper {
				max-width: 100%;
				bottom: -50 * 0.85px;
				height: 80 * 0.85px;
				border-radius: 30 * 0.85px;
				left: 50%;
				transform: translateX(-50%);
				padding: 0 24 * 0.85px 0 24 * 0.85px;
				font-size: 12 * 0.85px;

				.wrapDiv {
					width: 100%;
				}

				.btn-primary {
					color: white;
					background-color: #1278e8;
					//border-color: #707070;
					min-height: 32px;
					// width: 72px;
					font-size: 12px;
					border-radius: 12px;
					-webkit-border-radius: 12px;
					-moz-border-radius: 12px;
					-ms-border-radius: 12px;
					-o-border-radius: 12px;
					padding: 0;
				}

				a:nth-of-type(1),
				a:nth-of-type(2) {
					padding-bottom: 24 * 0.85px;
				}

				a:nth-of-type(3),
				.productRegisterBtn {
					padding-top: 24 * 0.85px;
				}

				.classBtnInnerWrapper {
					max-width: 624px;
					width: 100%;
					display: flex;
					justify-content: space-between;
				}
			}
		}

		.explore-section {
			&.section2 {
				padding-top: 30px;
				padding-bottom: 0;
				margin-top: 96px;

				h2 {
					font-size: 24px;
				}

				h6 {
					font-size: 14px;
				}
			}

			&.section3 {
				// max-width: 1096px;
				min-height: 360px;

				.cardArea {
					max-width: 1320px;
					margin: 0 auto;
					padding: 0;
				}

				.card {
					width: 440px;
					height: 360px;
					padding: 0 12px;
					margin-bottom: 24px;
					background-color: transparent;
					border: none;
				}

				.cardWords {
					height: 96px;
					margin-left: 0;
					margin-right: 0;
					border-bottom-left-radius: 24px;
					border-bottom-right-radius: 24px;

					.cardDesc {
						padding-left: 24px;
					}

					.cardTitle {
						font-size: 20 * 0.85px;
					}

					.cardCategory {
						font-size: 14 * 0.85px;
					}

					.cardVideoLinkContainer {
						border-left: 1px solid #989898;
					}

					.cardVideoLink {
						font-size: 14px;
						cursor: pointer;
						justify-content: end;
						padding-left: 0;
						padding-right: 24px;

						a {
							color: #282828;
							line-height: 1;
						}

						.arrowImg {
							height: 16px;
							width: 8px;
							cursor: pointer;
							background-image: url('../../../public/images/explore/arrowNext.png');
						}

						&:hover {
							a {
								color: #1278e8;
							}

							img {
								color: #1278e8;
							}

							.arrowImg {
								background-image: url('../../../public/images/explore/arrowNextHover.png');
							}
						}
					}
				}

				.faqTitle {
					height: 56px;
					cursor: pointer;
				}

				.faqSecondQuestion {
					cursor: pointer;
					font-size: 16px;
				}

				.faqSecondAnswer {
					font-size: 14px;
				}

				span.textActive {
					background-color: var(--warning);
				}

				.collapseWrapper button {
					font-size: 14px;
				}
			}
		}
	}
}
