#container-bldc {
	min-height: calc(100vh - 200px);
	margin-top: 105px;

	.articleBox {
		min-height: 632px;
		max-width: 1200px;

		.articleInnerBox {
			width: 100%;
			min-height: 300px;
			padding: 32px;
			border: 1px solid #dadada;
			border-radius: 30px;
			background-color: #ffffff;
		}
	}

	.switch__page-btn {
		margin: 24px 10px;
	}
}

@media only screen and (max-width: 992px) {
	#container-bldc {
		background-color: #ffffff;
		justify-content: start;

		.articleBox {
			padding: 32px 40px 44px 40px;
			border: none;
			border-radius: 0 0 8px 8px;
		}
	}
}
