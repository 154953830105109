#container-questionCategoryUpdate {
	.searchWrapper {
		button:nth-of-type(1) {
			&:hover {
				color: white;
			}
		}
	}

	.searchTableWrapper {
		.searchTableTop {
			button a {
				color: white;
			}

			span {
				cursor: pointer;
			}
		}

		.searchTableBottom {
			.deleteBtn svg {
				visibility: hidden;
			}

			tr:hover {
				background-color: #ecf4fd;
				--bs-table-hover-bg: rgba(0, 0, 0, 0);
				cursor: pointer;

				.deleteBtn svg {
					visibility: visible;
				}
			}
		}
	}
}
