#container-authUpdate {
	.authWrapper .bg-white {
		min-height: 540px;
	}

	th {
		cursor: pointer;
	}

	.tableWrapper {
		height: 460px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: var(--grey1);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--grey3);
		/*border-radius: 6px;*/
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: var(--grey4);
	}

	.authLeftWrapper {
		.titleWrapper {
			border-bottom: 1px solid var(--grey1);

			.btnWrapper .btn {
				width: 32px;
				height: 32px;
			}
		}

		.authGroupWrapper {
			.text-danger {
				color: #f01b47 !important;
			}
			cursor: pointer;

			&:hover,
			&.active {
				background-color: #ecf4fd;
			}
		}
	}

	.authRightWrapper {
		.tabWrapper {
			border-bottom: 1px solid var(--grey1);
			height: 48px;

			.tabBtn {
				width: 192px;
				height: 48px;
				line-height: 48px;
				font-size: 16px;
				cursor: pointer;

				&:hover,
				&.active {
					color: var(--primary);
					border-bottom: 1px solid var(--primary);
				}
			}
			.editBtn {
				width: 88px;
				height: 32px;
				line-height: 0;
			}
		}

		.tabContentWrapper_member {
			tbody {
				tr {
					--bs-table-hover-bg: rgba(236, 244, 253, 1) !important;
					&:hover,
					&.active {
						background-color: rgba(236, 244, 253, 1) !important;
					}

					cursor: pointer;
				}
			}

			.memberGroupWrapper {
				min-height: 460px;
			}
		}
	}
}
