#container-authUpdateMemberCreate {
	select {
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
		cursor: pointer;
	}

	th {
		cursor: pointer;
	}

	input:disabled {
		background-color: var(--grey1);
	}

	.tableWrapper {
		height: 395px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: var(--grey1);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--grey3);
		/*border-radius: 6px;*/
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: var(--grey4);
	}

	.bg-white {
		min-height: 540px;
	}

	.submitBtnWrapper {
		.btn {
			width: 88px;
			height: 32px;
			line-height: 0;
		}

		.btn-outline-primary:hover {
			color: white;
		}
	}

	.memberPageWrapper {
		.btn-outline-primary {
			&:hover {
				color: white !important;
			}
		}

		thead th {
			label,
			input {
				cursor: pointer;
			}
		}

		tbody {
			tr {
				--bs-table-hover-bg: rgba(236, 244, 253, 1) !important;
				&:hover,
				&.active {
					background-color: rgba(236, 244, 253, 1) !important;
				}

				label,
				input {
					cursor: pointer;
				}

				td.memberCheckboxWrapper {
					width: 48px;

					label {
						cursor: pointer;
					}
				}
			}
		}
	}
}
