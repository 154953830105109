.supportBottom {
	min-height: auto;

	.desc {
		max-width: 1168px;
		padding-bottom: 144px;

		ul {
			list-style: none;
		}
	}

	.moreInfo {
		max-width: 1320px;
		background-color: var(--background);
		border-radius: 30px;

		p {
			font-size: 24px;
		}
		h6 {
			font-size: 14px;
		}
	}
}

@media only screen and (max-width: 992px) {
	.supportBottom {
		min-height: 0;

		.moreInfo {
			max-width: 328px;

			.row .col-12 .border-end {
				border-right-width: 0 !important;
				border-bottom: 1px solid var(--grey5);
				margin-inline: 32px;
			}

			p {
				font-size: 16px;
			}
			h6 {
				font-size: 12px;
				line-height: 14px;
			}

			img {
				height: 14px !important;
			}
		}
	}
}
