#container-appUpdateDetail {
	input,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}

	select {
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.btnCancel {
		&:hover {
			color: white;
		}
	}

	.manageFileWrapper {
		button:nth-of-type(1) {
			&:hover {
				color: white;
			}
		}

		.fileView {
			height: 32px;
		}

		.cancelFileBtn {
			cursor: pointer;
		}
	}

	.infoWrapper {
		.infoTop {
			span {
				cursor: pointer;
			}
		}
	}
}
