#container-appDownload {
	.appDownload-section {
		min-height: calc(100vh - 75px);

		&.section1 {
			margin-top: 104px;
			max-width: 1216px;

			.section1AppIcon {
				width: 120 * 0.85px;
				height: 120 * 0.85px;
			}

			h1 {
				font-size: 50 * 0.85px;
			}

			p {
				font-size: 20 * 0.85px;
			}

			.btnIcon {
				width: 96 * 0.85px;
				height: 48 * 0.85px;
			}

			.btn-primary {
				height: 48 * 0.85px;
				color: white;
				font-size: 14 * 0.85px;
				&:hover {
					color: white;
				}
			}

			.section1Phone {
				max-height: 65vh;
			}
		}

		&.section2 {
			background-image: url('../../../../public/images/appDownload/section2.png');
			height: 100%; /* You must set a specified height */
			background-position: center; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			background-size: cover; /* Resize the background image to cover the entire container */

			h1 {
				font-size: 50 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1000px;
			}
		}

		&.section3 {
			.section3Img {
				max-height: 80vh;
			}
			h1 {
				font-size: 50 * 0.85px;
			}

			p {
				font-size: 20 * 0.85px;
				max-width: 550px;
			}

			h6 {
				font-size: 16 * 0.85px;
			}
		}

		&.section4 {
			background-image: url('../../../../public/images/appDownload/section4Bg.png');
			height: 100%; /* You must set a specified height */
			background-position: center; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			background-size: cover; /* Resize the background image to cover the entire container */

			h1 {
				max-width: 600px;
				font-size: 50 * 0.85px;
			}

			p {
				max-width: 600px;
				font-size: 20 * 0.85px;
			}

			.section4Phone {
				max-height: 65vh;
			}
		}

		&.section4_2 {
			.section4_2_Phone {
				max-height: 65vh;
			}
			h1 {
				font-size: 50 * 0.85px;
			}

			p {
				font-size: 20 * 0.85px;
				max-width: 550px;
			}

			h6 {
				font-size: 16 * 0.85px;
			}
		}

		&.section5 {
			.section5Phone {
				max-height: 690 * 0.85px;
			}
			h1 {
				font-size: 50 * 0.85px;
			}
			p {
				font-size: 20 * 0.85px;
				max-width: 600px;
			}

			ul {
				list-style: none;

				li {
					font-size: 20 * 0.85px;
				}
			}
		}

		&.section5_2 {
			.section5_2_Phone {
				max-height: 65vh;
			}
			h1 {
				font-size: 50 * 0.85px;
			}

			p {
				font-size: 20 * 0.85px;
				max-width: 550px;
			}

			h6 {
				font-size: 16 * 0.85px;
			}
		}

		&.section5_3 {
			h1 {
				font-size: 50 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
			}
		}

		&.section6 {
			.section1AppIcon {
				width: 120 * 0.85px;
				height: 120 * 0.85px;
			}

			h1 {
				font-size: 50 * 0.85px;
			}

			h2 {
				font-size: 36 * 0.85px;
				font-weight: normal;
			}

			.section6IosQr,
			.section6AndroidQr {
				width: 240 * 0.85px;
				height: 240 * 0.85px;
			}

			.section6IosLogo,
			.section6AndroidLogo {
				width: 256 * 0.85px;
				height: 77.12 * 0.85px;
				cursor: pointer;
			}

			.section6Phone {
				max-height: 656 * 0.85px;
			}
		}

		&.section7 {
			min-height: auto;
			margin-bottom: 150 * 0.85px;
			font-size: 16 * 0.85px;

			ul {
				list-style: none;
			}

			.col-12 {
				max-width: 1168px;
			}
		}
	}

	.sameBgWrap {
		background-image: url('../../../../public/images/appDownload/section5Bg.png');
		width: 100%;
		background-position: 0 6%; /* Center the image */
		background-repeat: no-repeat; /* Do not repeat the image */
		background-size: contain; /* Resize the background image to cover the entire container */
	}
}

@media screen and (min-width: 1600px) {
	#container-appDownload {
		background-color: #ffffff;
		justify-content: start;

		.appDownload-section {
			&.section6 {
				.qrCodeWrapper {
					padding-left: 256px !important;
				}
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-appDownload {
		background-color: #ffffff;
		justify-content: start;

		.appDownload-section {
			&.section1 {
				margin-top: 48px;

				h1 {
					font-size: 24px;
				}
				p {
					font-size: 16px;
				}
				.btnIcon {
					width: 64px;
					height: 40px;
				}
				.btn-primary {
					width: 136px;
					height: 40px;
					font-size: 12px;
				}
				.section1Phone {
					width: 75%;
				}
			}

			&.section2 {
				height: 100vh;

				h1 {
					font-size: 32px;
				}
				p {
					font-size: 14px;
				}
			}

			&.section3 {
				.section3Img {
					width: 75% !important;
					max-height: none;
				}
				h1 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
				}
				.section3Img {
					width: 100%;
				}
			}

			&.section4 {
				background-image: none;

				.section4Phone {
					max-height: none;
				}
				h1 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
				}

				.section4Phone {
					width: 100%;
				}
			}

			&.section4_2 {
				.section4_2_Phone {
					width: 75% !important;
					max-height: none;
				}

				h1 {
					font-size: 24px;
				}

				p {
					font-size: 14px;
				}
			}

			&.section5 {
				.section5Phone {
					width: 75% !important;
					max-height: none;
				}
				h1 {
					font-size: 24px;
				}
				ul li {
					font-size: 14px;
				}
				.btn-outline-secondary {
					font-size: 14px;
				}
			}

			&.section5_2 {
				.section5_2_Phone {
					width: 75% !important;
					max-height: none;
				}
				h1 {
					font-size: 24px;
				}

				p {
					font-size: 14px;
				}

				h6 {
					font-size: 12px;
				}
			}

			&.section5_3 {
				min-height: auto;
				h1 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
				}
			}

			&.section6 {
				h1 {
					font-size: 24px;
				}
				h2 {
					font-size: 16px;
				}
				p {
					font-size: 12px;
				}
				.section6IosLogo,
				.section6AndroidLogo {
					width: 160px;
					height: 48.4px;
				}
				.section6Phone {
					width: 75% !important;
					max-height: none;
				}
			}

			&.section7 {
				font-size: 12px;
			}
		}

		.sameBgWrap {
			background-position: 0 35%; /* Center the image */
		}
	}
}
