#container-forgotPwd {
	min-height: calc(100vh - 176px);
	margin-top: 80px;

	.pageTitle-mobile {
		margin-top: 32 * 0.85px;
		margin-bottom: 8 * 0.85px;
		font-size: 24 * 0.85px;
	}

	.articleBox {
		min-height: 576 * 0.85px;

		#email,
		#countryCode,
		#phoneCode,
		#password,
		#verificationCode,
		#newPWD,
		#confirmPWD {
			font-size: 16 * 0.85px;
			height: 48 * 0.85px;
		}

		p,
		a {
			font-size: 14 * 0.85px;
		}

		.promptMessageBox {
			margin-top: 40 * 0.85px;
		}

		.confirmBtnBox {
			width: 312 * 0.85px;
			margin: 0 auto;

			.confirmBtn {
				height: 48 * 0.85px;
			}
		}

		.changeBox {
			margin-top: 32 * 0.85px;
		}

		#resendVerifyBtn {
			width: 100%;
			font-size: 14 * 0.85px;
			height: 48 * 0.85px;
		}
	}

	/* 高度固定框 */
	.contentBox {
		height: 224 * 0.85px;
	}
}

@media only screen and (max-width: 992px) {
	#container-forgotPwd {
		background-color: #ffffff;
		justify-content: start;
		margin-top: 48px;
		min-height: calc(100vh - 151px);

		.articleBox {
			min-height: calc(100vh - 420px);
			padding: 16px;
			margin: 8px;
			border: none;
			border-radius: 8px;

			.promptMessageBox {
				margin-top: 32px;
			}

			.changeBox {
				margin-top: 8px;
			}
		}
	}
}
