#container-productionRecordChange {
	input,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
		font-size: 14px;
	}

	select {
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.svgMore {
		margin-bottom: 2px;
	}

	.moreIconBtn:hover .svgMore path {
		fill: white;
	}

	.btnCancel {
		&:hover {
			color: white;
		}
	}

	.btn-outline-primary {
		&:hover {
			color: white;
		}
	}
}
