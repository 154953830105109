#container-footer #footer {
	.wrapper {
		max-width: 1140px;
	}

	.footerTop {
		height: 320px;
	}

	.footerBottom {
		height: 96px;
		font-size: 14px;
	}

	// accordion
	.accordion-button {
		color: #ffffff;
		background-color: #282828;
		justify-content: center;
		font-size: 14px;
		padding: 8px 20px;
		pointer-events: none;

		&::after {
			width: 0;
			margin: 0;
		}

		&:focus {
			box-shadow: none;
		}
	}

	.accordion-body {
		padding: 0;

		ul li {
			margin-bottom: 10px;
			text-align: center;

			a,
			.navFakeLink {
				display: block;
				width: 100%;
				font-size: 12px;
				color: #989898;
				cursor: pointer;
			}

			&:hover a,
			&:hover .navFakeLink {
				color: #1278e8;
			}
		}
	}
		.privacyPolicyHref {
			padding-left: 8px;
			
			a{
				color: #989898 !important;
				cursor: pointer;
			}
		}
	
		.userAgreementHref {
			border-right: 1px solid #989898;
			padding-right: 8px;
	
			a {
				color: #989898 !important;
				cursor: pointer;
			}
		}
	
	}


@media only screen and (max-width: 768px) {
	#container-footer #footer {
		.footerTop {
			min-height: 200px;
			height: 100%;

			ul li {
				padding-left: 20px;
				text-align: left;
			}
		}

		.footerBottom {
			min-height: 88px;
			height: 100%;
			font-size: 12px;
		}

		// accordion
		.accordion-button {
			justify-content: start;
			pointer-events: auto;
		}

		.copyRightFooter {
			width: 100% !important;
			flex-direction: column;
			margin-bottom: 16px;

			span {
				text-align: start !important;
			}
		}

		.privacyFooter  {
			width: 100% !important;
			justify-content: start !important;
		}

		.footerCompanyEngName, .footerCopyRightWord{
			display: none;
		}
		
	}
}

@media only screen and (min-width: 768px) {
	#container-footer #footer {
		// desktop version open all accordion
		.accordion-collapse {
			display: block;
		}
	}
}
