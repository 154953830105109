#header-app {
	.head-menu-second-Bg.productCenterNav {
		ul {
			max-width: 1250px;
			padding-left: 0;
			height: 100%;
			align-items: center;

			li:first-child {
				cursor: auto;
				a,
				span {
					cursor: auto;
				}
				&:hover span {
					color: var(--grey5);
				}
			}
		}
	}
}

#container-productFeature {
	.productFeature-section {
		min-height: calc(100vh - 50px);

		&.section0 {
			display: none;
		}

		&.section1 {
			margin-top: 90px;

			background-image: url('../../../../public/images/productCenter/productFeature/section1_1_2x.png');
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			h1 {
				font-size: 50 * 0.85px;
				margin-top: 550 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 880px;
			}
			button {
				font-size: 16 * 0.85px;
			}
		}

		&.section2 {
			h1 {
				font-size: 50 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1168px;
			}
		}

		&.section3 {
			min-height: auto;

			h2 {
				font-size: 36 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1168px;
			}
			h6 {
				font-size: 16 * 0.85px;
			}
			.textWrap {
				max-width: 660px;
			}
		}

		&.section4 {
			height: calc(100vh - 100px);
			min-height: calc(100vh - 100px);

			background-image: url('../../../../public/images/productCenter/productFeature/section3_1_2x.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			h2 {
				font-size: 36 * 0.85px;
				margin-top: 435 * 0.85px !important;
			}
			p {
				font-size: 16 * 0.85px;
				max-width: 600px;
			}
			.btn-outline-primary {
				width: 312 * 0.85px;
				height: 48 * 0.85px;
				font-size: 14 * 0.85px;
			}
		}

		&.section5 {
			h1 {
				font-size: 50 * 0.85px;
				margin-top: 328 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1168px;
			}
		}

		&.section6 {
			min-height: auto;

			h2 {
				font-size: 36 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1168 * 0.85px;
			}
			h6 {
				font-size: 16 * 0.85px;
			}
			.textWrap {
				max-width: 660px;
			}
		}

		&.section7 {
			min-height: 912 * 0.85px;

			h2 {
				font-size: 36 * 0.85px;
			}

			h3 {
				font-size: 24 * 0.85px;
			}

			.section7InfoBox {
				max-width: 1320px;
				top: 0;
				left: 50%;
				height: 100%;
				width: 100%;
				transform: translateX(-50%);

				h6 {
					font-size: 16 * 0.85px;
				}

				.leftWrap {
					width: 424 * 0.85px;
					bottom: 295 * 0.85px;
				}
				.rightTopWrap {
					width: 424 * 0.85px;
					top: 240 * 0.85px;
					right: 0;
				}
				.rightBottomWrap {
					width: 424 * 0.85px;
					bottom: 232 * 0.85px;
					right: 0;
				}
			}
		}

		&.section8 {
			h2 {
				font-size: 36 * 0.85px;
				max-width: 960px;
			}
			p {
				font-size: 20 * 0.85px;
				max-width: 960px;
			}

			h6 {
				font-size: 16 * 0.85px;
			}
			.textWrap {
				max-width: 660px;
			}
		}

		&.section9 {
			min-height: auto;

			h2 {
				font-size: 36 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1168px;
			}
		}

		&.section10 {
			min-height: 912 * 0.85px;

			h2 {
				font-size: 36 * 0.85px;
			}

			.section10CardBox {
				max-width: 1320px;

				h3 {
					font-size: 24 * 0.85px;
					max-width: 248px;
				}

				h6 {
					font-size: 16 * 0.85px;
					max-width: 248px;
				}

				.cardLeftTop {
					background-image: url('../../../../public/images/productCenter/productFeature/section10LeftTop_1_2x.png');
					height: 408 * 0.85px;
					width: 648 * 0.85px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					.cardInfo {
						top: 0;
						right: -36px;
					}
				}
				.cardRightTop {
					background-image: url('../../../../public/images/productCenter/productFeature/section10RightTop_1_2x.png');
					height: 408 * 0.85px;
					width: 672 * 0.85px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					.cardInfo {
						bottom: 0;
						left: 0;
					}
				}
				.cardLeftBottom {
					background-image: url('../../../../public/images/productCenter/productFeature/section10LeftBottom_1_2x.png');
					height: 408 * 0.85px;
					width: 624 * 0.85px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					.cardInfo {
						bottom: 0;
						left: 0;
					}
				}
				.cardRightBottom {
					background-image: url('../../../../public/images/productCenter/productFeature/section10RightBottom_1_2x.png');
					height: 408 * 0.85px;
					width: 696 * 0.85px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					.cardInfo {
						top: 0;
						left: 0;
					}
				}
			}

			.btn-outline-primary {
				width: 312 * 0.85px;
				height: 48 * 0.85px;
				font-size: 14 * 0.85px;
			}
		}

		&.section11 {
			min-height: auto;
			padding-block: 328 * 0.85px;

			h1 {
				font-size: 50 * 0.85px;
			}
			h2 {
				font-size: 36 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1210px;
			}

			h6 {
				font-size: 16 * 0.85px;
			}
			.textWrap {
				max-width: 660px;
			}
		}

		&.section13 {
			min-height: auto;
			padding-top: 144 * 0.85px;
			padding-bottom: 330 * 0.85px;

			h2 {
				font-size: 36 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1168px;
			}
		}

		&.section14 {
			min-height: 912 * 0.85px;

			h2 {
				font-size: 36 * 0.85px;
			}

			h3 {
				font-size: 24 * 0.85px;
			}

			.section14InfoBox {
				max-width: 1200px;
				top: 0;
				left: 50%;
				height: 100%;
				width: 100%;
				transform: translateX(-50%);

				h6 {
					font-size: 16 * 0.85px;
				}

				.leftTopWrap {
					width: 376 * 0.85px;
					top: -74 * 0.85px;
					left: 85 * 0.85px;
				}
				.rightTopWrap {
					width: 376 * 0.85px;
					top: 240 * 0.85px;
					top: -74 * 0.85px;
					right: 0;

					ul {
						list-style: none;
					}
				}
				.leftBottomWrap {
					width: 520 * 0.85px;
					bottom: -16 * 0.85px;
					left: 160 * 0.85px;
				}
			}

			.btn-outline-black {
				width: 312 * 0.85px;
				height: 48 * 0.85px;
				font-size: 14 * 0.85px;
				color: var(--grey5);
				border: 1px solid var(--grey5);

				&:hover {
					color: white;
					background-color: var(--grey5);
				}
			}
		}

		&.section15 {
			min-height: auto;
			padding-top: 328 * 0.85px;
			padding-bottom: 234 * 0.85px;

			h1 {
				font-size: 50 * 0.85px;
			}
			h2 {
				font-size: 36 * 0.85px;
			}
			p {
				font-size: 24 * 0.85px;
				max-width: 1210px;
			}
			.textWrap {
				max-width: 704px;
			}
		}

		&.section16 {
			min-height: auto;

			.desc {
				max-width: 1168px;
				padding-bottom: 144 * 0.85px;

				ul {
					list-style: none;
				}

				p,
				li {
					font-size: 16 * 0.85px;
				}
			}

			// .afterSalesService {
			// 	.btn-primary {
			// 		width: 216 * 0.85px;
			// 		height: 40 * 0.85px;
			// 	}
			// }
		}
	}
}

@media only screen and (max-width: 992px) {
	#header-app {
		.head-menu-second-Bg.productCenterNav {
			display: none;
		}
	}

	#container-productFeature {
		background-color: #ffffff;
		justify-content: start;

		.productFeature-section {
			&.section0 {
				display: block;
				top: 48px;
				min-height: 48px;
				z-index: 88;

				.section0Wrapper {
					max-width: 1222px;
					line-height: 48px;

					.dropdown-menu {
						min-width: 0;
						line-height: 16px;

						a:active {
							color: var(--grey5);
							background-color: transparent;
						}
					}

					h6,
					button,
					a {
						font-size: 12px;
					}
				}
			}

			&.section1 {
				margin-top: 97px;
				min-height: 0;

				background-image: url('../../../../public/images/productCenter/productFeature/section1_1_1x.png');
				background-size: contain;
				background-position: top;

				h1 {
					font-size: 24px;
					margin-top: 605px;
				}
				button {
					font-size: 14px;
				}
			}

			&.section2 {
				h1 {
					font-size: 32px;
				}
				p {
					font-size: 14px;
				}
			}

			&.section3 {
				h2 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
					max-width: 1168px;
				}
				.textWrap {
					h2 {
						font-size: 16px;
					}
					h6 {
						font-size: 10px;
					}
				}
			}

			&.section4 {
				height: auto !important;
				min-height: 0;
				background-image: none;
				margin-bottom: 64px;

				h2 {
					font-size: 24px;
					margin-top: 40px !important;
				}
				p {
					font-size: 14px;
				}
				.btn-outline-primary {
					width: 112px;
					height: 40px;
					font-size: 14px;
				}
			}

			&.section5 {
				min-height: 0;
				h1 {
					font-size: 32px;
					margin-top: 64px;
				}
				p {
					font-size: 14px;
				}
			}

			&.section6 {
				min-height: auto;

				h2 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
				}
				.textWrap {
					h2 {
						font-size: 16px;
					}
					h6 {
						font-size: 10px;
					}
				}
			}

			&.section7 {
				min-height: 0;

				h2 {
					font-size: 24px;
				}

				h3 {
					font-size: 16px;
				}

				h6 {
					font-size: 14px;
				}

				.sliderBtnWrapper {
					width: 72px;
					height: 24px;
				}
			}

			&.section8 {
				min-height: 0;
				margin-bottom: 104px;

				h2 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
				}

				.textWrap {
					h2 {
						font-size: 16px;
					}
					h6 {
						font-size: 10px;
					}
				}
			}

			&.section9 {
				min-height: 0;

				h2 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
					margin-bottom: 144px;
				}
			}

			&.section10 {
				min-height: 0;

				h2 {
					font-size: 24px;
				}

				.section10CardBox {
					h3 {
						font-size: 16px;
					}

					h6 {
						font-size: 14px;
					}
				}

				.btn-outline-primary {
					width: 112px;
					height: 40px;
					font-size: 14px;
				}
			}

			&.section11 {
				min-height: 0;
				padding-top: 64px;
				padding-bottom: 40px;

				h1 {
					font-size: 32px;
				}
				p {
					font-size: 14px;
				}

				.textWrap {
					h2 {
						font-size: 16px;
					}
					h6 {
						font-size: 10px;
					}
				}
			}

			&.section12 {
				min-height: 0;
			}

			&.section13 {
				min-height: 0;
				padding-top: 40px;
				padding-bottom: 56px;

				h2 {
					font-size: 24px;
				}
				p {
					font-size: 14px;
				}
			}

			&.section14 {
				min-height: 0;

				h3 {
					font-size: 16px;
				}

				h6 {
					font-size: 14px;
				}

				ul {
					list-style-type: none;
				}

				.sliderBtnWrapper {
					width: 72px;
					height: 24px;
				}

				.btn-outline-black {
					width: 112px;
					height: 40px;
					font-size: 14px;
					color: var(--grey5);
					border: 1px solid var(--grey5);

					&:hover {
						color: white;
						background-color: var(--grey5);
					}
				}
			}

			&.section15 {
				min-height: 0;
				padding-top: 64px;
				padding-bottom: 48px;

				h1 {
					font-size: 32px;
				}
				h2 {
					font-size: 10px;
				}
				p {
					font-size: 14px;
				}

				img {
					height: 32px !important;
				}
			}

			&.section16 {
				min-height: 0;

				.desc {
					padding-bottom: 40px;
					p,
					li {
						font-size: 12px;
					}
					ul {
						list-style: none;
					}
				}

				// .afterSalesService {
				// 	.btn-primary {
				// 		width: 216px;
				// 		height: 40px;
				// 	}
				// }
			}
		}
	}
}
