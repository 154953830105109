$primary: #1278e8;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #cc3300;
$light: #f8f9fa;
$dark: #212529;

//custom color to be mapped
$background: #f8f8f8;
$grey1: #f4f4f4;
$grey2: #dadada;
$grey3: #989898;
$grey4: #686868;
$grey5: #282828;

// this is used to map colors. change accordingly.
$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
	dark: $dark,
	// add any additional color below
	background: $background,
	grey1: $grey1,
	grey2: $grey2,
	grey3: $grey3,
	grey4: $grey4,
	grey5: $grey5,
	// now bg-grey1,btn-grey1,etc.. can be called
);

//自定義spacer
$spacer: 1rem;
$spacers: ();

$custom-spacers: (
	0: 0,
	1: (
		$spacer * 0.25,
	),
	2: (
		$spacer * 0.5,
	),
	3: (
		$spacer * 1,
	),
	4: (
		$spacer * 1.5,
	),
	5: (
		$spacer * 2,
	),
	6: (
		$spacer * 2.5,
	),
	7: (
		$spacer * 3,
	),
	8: (
		$spacer * 3.5,
	),
	9: (
		$spacer * 4,
	),
	10: (
		$spacer * 4.5,
	),
	11: (
		$spacer * 5,
	),
	12: (
		$spacer * 6,
	),
	13: (
		$spacer * 8,
	),
	14: (
		$spacer * 10,
	),
	15: (
		$spacer * 12,
	),
	16: (
		$spacer * 16,
	),
);
$spacers: map-merge($spacers, $custom-spacers);
/*
 0 =   0px
 1 =   4px   2 =  8px   3 =  16px   4 =  24px
 5 =  32px   6 = 40px   7 =  48px   8 =  56px
 9 =  64px  10 = 72px  11 =  80px  12 =  96px
13 = 128px 14 = 160px  15 = 192px  16 = 256px
*/

// table
$table-cell-padding-y: 1rem !default;
