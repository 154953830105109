#container-privacy {
	* {
		font-size: 16px;	
	}

	.outerWrapper {
		background-color: #f8f8f8;
		margin-top: 80px;  
		width: 100%;
		display: flex;
		justify-content: center;
	}
		
	.wrapper {
		margin-top: 72px;
		background-color: #ffffff;  
		width: 100%;
		max-width: 1320px;
		padding: 64px 0;
	}

	main {
		max-width: 896px;
	}

	.row {
		margin-left: 0;
		margin-right: 0;

        * { 
            padding-left: 0;
            padding-right: 0;
        }
	}

	h1 {
		margin: 0;
		text-align: center;
		font-size: 36px;
	}

	h2 {
		margin: 0;
		font-size: 20px;
		font-weight: bold;
	}

	p{
		margin: 16px 0;
	}

	section {
		margin-top: 32px;
		margin-bottom: 8px;
	}


	.wordWrap {
		margin-top: 16px;
	}

	.textOuterWrapper {
		margin-bottom: 16px;
	}

	.subtitle {
		margin: 16px 0 0 0;
		font-weight: bold;
	}

	.subtitle ~ .wordWrap {
		p {
			margin-left: 28px;
		}
	}

}


@media only screen and (max-width: 768px) {
	#container-privacy {

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 16px;
		}

		p {
			font-size: 12px;
		}

		.outerWrapper {
			margin-top: 48px;
		}
		.wrapper {
			margin-top: 0;
			padding: 72px 0;
		}

		main {
			margin: 0 16px;
		}

		.subSection + p {
			margin-left: 16px;
		}

		.subtitle {
			font-size: 14px;
		}

		.subtitle ~ .wordWrap p {
			margin-left: 16px;
		}
	}
}