#container-authUpdateGroupName {
	.submitBtnWrapper {
		.btn {
			width: 88px;
			height: 32px;
			line-height: 0;
		}

		.btn-outline-primary:hover {
			color: white;
		}
	}
}
