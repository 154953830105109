﻿/* Color */
:root {
	--primary: #1278e8;
	--secondary: #6c757d;
	--success: #198754;
	--info: #0dcaf0;
	--warning: #ffc107;
	--danger: #cc3300;
	--light: #f8f9fa;
	--dark: #212529;

	//custom color to be mapped
	--background: #f8f8f8;
	--grey1: #f4f4f4;
	--grey2: #dadada;
	--grey3: #989898;
	--grey4: #686868;
	--grey5: #282828;

	/* font */
	--mainFontSize: 1rem;
}

/* font */
body {
	font-size: var(--mainFontSize);
	color: var(--grey3);
}

.fontSize75Rem {
	font-size: 0.75rem !important;
}

.fontSize875Rem {
	font-size: 0.875rem !important;
}

.fontSize125Rem {
	font-size: 1.25rem !important;
}

.fontSize2Rem {
	font-size: 2rem !important;
}

.rounded-4 {
	border-radius: 0.8rem;
}

button,
input {
	box-shadow: none !important;
}

/* -------------------- 預設元件 -------------------- */
body {
	font-family: Microsoft YaHei, noto sans, Microsoft JhengHei;
	font-size: 16px;
	color: #282828;
}

a {
	color: #76adff;
	text-decoration: none;

	&:hover {
		color: #1278e8;
	}
}

/* 輸入框 */
.form-control,
.form-select {
	border: 1px solid #dadada;
}

// .btn {
// 	font-size: 1rem;
// 	height: 40px;
// }

/* 輸入框條 */
// .form-row {
// 	margin-right: -8px;
// 	margin-left: -8px;
// }

/* 群組化輸入按鈕 */
// .input-group-append .btn {
// 	z-index: 1;
// }

/* Checkbox */
// .custom-checkbox .custom-control-label::before,
// .custom-checkbox .custom-control-label::after {
// 	width: 20px;
// 	height: 20px;
// }

/* -------------------- 通用元件 -------------------- */

/* 按鈕大小 */
// .btn-size-xs {
// 	width: 40px;
// 	height: 40px;
// }

.btn-size-s {
	max-width: 80px;
	width: 100%;
	height: 40px;
}

.btn-size-m {
	max-width: 128px;
	width: 100%;
	height: 48px;
	font-size: 14px;
}

.btn-size-l {
	max-width: 312px;
	width: 100%;
	height: 48px;
}

/* 按鈕顏色 */
.btn-color-main {
	color: #ffffff;
	background-color: #1278e8;
	border-color: #1278e8;

	&:hover {
		color: #ffffff;
		background-color: #76adff;
		border-color: #76adff;
	}

	&:disabled:hover {
		color: #ffffff;
	}
}

.btn-color-auxiliary {
	color: #ffffff;
	background-color: #282828;
	border-color: #282828;
	&:hover {
		color: #ffffff;
		background-color: #686868;
		border-color: #686868;
	}

	&:disabled:hover {
		color: #ffffff;
	}
}

.btn-color-outline-auxiliary {
	color: #686868;
	background-color: #ffffff;
	border-color: #686868;
	&:hover {
		color: #ffffff;
		background-color: #686868;
		border-color: #ffffff;
	}

	&:disabled:hover {
		color: #686868;
	}
}

.btn-color-outline-transparent-auxiliary {
	color: #282828;
	background-color: transparent;
	border-color: #282828;
	&:hover {
		color: #ffffff;
		background-color: #282828;
		border-color: #282828;
	}
}

.btn-hover-white {
	color: white;
	border: 1px solid white;
	background-color: transparent;
	&:hover {
		color: #000;
		background-color: white;
	}
}

.btn-color-outline-white {
	color: #282828;
	background-color: #ffffff;
	border-color: #989898;
	&:hover {
		border-color: #282828;
	}
}

/* 按鈕形狀 */
// .btn-circle {
// 	border-radius: 20px;
// }

/* 背景顏色 */
// .bg-main {
// 	background-color: #008eda !important;
// }

// .bg-similar {
// 	background-color: #daeeff !important;
// }

/* 灰框 -- 無SideBox */
.articleBox {
	max-width: 560 * 0.85px;
	padding: 45 * 0.85px 56 * 0.85px;
	border: 1px solid #dadada;
	border-radius: 30px;
	background-color: #ffffff;
}

/* 灰框 -- 有SideBox */
.articleBoxWithSideBox {
	@extend .articleBox;
	max-width: 100%;
	position: relative;
	padding: 88px 112px 56px 112px;
}

/* 輸入條格子 */
// .colBox {
// 	padding: 0px 8px;
// }

/* 分隔線 */
// .hr-dividingLine {
// 	border-color: #dadada;
// }

/* 表格 */
// .table-custom thead th {
// 	border: 0px;
// 	background-color: #f4f4f4;
// }

/* .table-custom thead th:first-child {
    border-radius: 4px 0px 0px 4px;
}
.table-custom thead th:last-child {
    border-radius: 0px 4px 4px 0px;
} */

/* 藍標視窗 */
// .card-custom .card-header {
// 	background-color: #daeeff;
// 	font-weight: bold;
// }

// .card-custom .card-body {
// 	padding: 16px;
// }

/* 手機CSS */
// .mobile {
// 	display: none !important;
// }

// .mobile-td {
// 	display: none !important;
// }

// .mobile-td p {
// 	margin: 0;
// }

// @media screen and (max-width: 800px) {
// 	.desktop {
// 		display: none !important;
// 	}

// 	.mobile {
// 		display: block !important;
// 	}

// 	.mobile-td {
// 		display: table-cell !important;
// 	}
// }

/* -------------------- 特殊元件 -------------------- */

// 無sidebar的頁標題
.pageTitle {
	font-size: 36 * 0.85px;
	margin-bottom: 40 * 0.85px;
	text-align: center;
}

// 有sidebar的頁標題
.containTitle {
	position: absolute;
	top: 32px;
	left: 40px;
	font-size: 20px;
}

/* 頁角 */
// .section-footer {
// 	margin-top: 48px;
// 	margin-bottom: 48px;
// 	text-align: center;
// }

// .section-footer button:nth-child(2) {
// 	margin-left: 32px;
// }

/* 模式切換按鈕 */
// .btn-modeSwitch {
// 	margin-top: 16px;
// 	margin-bottom: 16px;
// }

/* 驗證碼輸入框 */
// #captcha {
// 	max-width: 112px;
// }

// /* 國碼 */
// #countryCode {
// 	width: 90px;
// }

// /* 手機號 */
// #phoneCode {
// 	width: 206px;
// }

/* Range輸入框 */
// .rangeBox {
// 	display: flex;
// }

// .rangeBox .custom-range {
// 	max-width: 300px;
// 	padding-top: 14px;
// 	margin: 0px 16px;
// }

// .swal2-actions .btn {
// 	margin: 0px 8px;
// }

/* 頁身 */
#main-app {
	// 給予一個基本高度撐開空間，避免未登入時，某些頁面無資料，footer跑到最上方
	min-height: calc(100vh - 300px);
}

// 後台管理 renderIndexList 共用元件之 CSS
.renderIndexListSearchTableBottom {
	.renderIndexListDeleteBtn svg {
		visibility: hidden;
	}

	tr:hover {
		background-color: #ecf4fd;
		--bs-table-hover-bg: rgba(0, 0, 0, 0);
		cursor: pointer;

		.renderIndexListDeleteBtn svg {
			visibility: visible;
		}
	}
}

@media only screen and (max-width: 992px) {
	#main-app {
		background-color: #f8f8f8;
		.row-custom {
			padding: 0;
		}
	}
}

/* 頁腳 */
