#container-login {
	min-height: calc(100vh - 176px);
	margin-top: 80px;

	.articleBox {
		#emailID,
		#countryCode,
		#phoneCode,
		#password,
		#captcha,
		#btn-login {
			font-size: 16 * 0.85px;
			height: 48 * 0.85px;
		}

		.form-check-label,
		.loginToggleDiv,
		.changeBox {
			font-size: 14 * 0.85px;
		}

		#passwordSwitch {
			height: 48 * 0.85px;
			z-index: 0;
			border-color: #dadada;
			border-left-color: #fff;
			border-radius: 0 0.3rem 0.3rem 0;
		}

		#capcha,
		.captchaDiv,
		.refreshBtn {
			height: 48 * 0.85px;
		}

		.loginToggleDiv {
			margin-bottom: 42px;
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-login {
		background-color: #ffffff;
		justify-content: start;
		margin-top: 48px;
		min-height: calc(100vh - 151px);

		.articleBox {
			min-height: calc(100vh - 151px);
			padding: 32px 16px 32px 16px;
			border: none;
			border-radius: 0 0 8px 8px;

			.pageTitle {
				font-size: 24px;
				margin-bottom: 32px;
			}

			.loginToggleDiv {
				margin-top: 24px;
				margin-bottom: 38px;
			}
		}
	}
}
