#container-supportContactUs {
	.supportContactUs-section {
		&.section2 {
			min-height: auto;
			padding-block: 56px;
			margin-top: 120px;

			h2 {
				font-size: 36px;
			}

			h6 {
				font-size: 16px;
				max-width: 1168px;
			}
		}

		&.section3 {
			max-width: 1096px;
			min-height: auto;
			border-radius: 30px;

			p {
				font-size: 24px;
			}

			h5 {
				font-size: 16px;
			}

			h6,
			.locationText {
				font-size: 14px;
			}
		}

		&.section4 {
			min-height: auto;
			padding-top: 240px;

			h2 {
				font-size: 36px;
			}

			h6 {
				font-size: 16px;
				max-width: 1168px;
			}
		}

		&.section5 {
			min-height: auto;
			padding-top: 112px;
			padding-bottom: 88px;

			h1 {
				font-size: 50px;
			}
			h2 {
				font-size: 36px;
			}
			p {
				font-size: 24px;
			}
			h6 {
				font-size: 16px;
				max-width: 1210px;
			}
			.textWrap {
				max-width: 704px;
			}
		}

		&.section6 {
			max-width: 1096px;
			min-height: auto;

			p {
				font-size: 24px;
			}

			h6 {
				font-size: 12px;
			}

			.btn {
				font-size: 14px;
				width: 160px;
				height: 48px;
				line-height: 36px;
			}
		}

		&.section7 {
			min-height: auto;

			.desc {
				max-width: 1168px;
				padding-bottom: 144px;

				ul {
					list-style: none;
				}
			}

			.moreInfo {
				max-width: 1320px;
				background-color: #f2f2f2;
				border-radius: 30px;

				p {
					font-size: 24px;
				}
				h6 {
					font-size: 14px;
				}
			}

			.afterSalesService {
				.btn-primary {
					width: 216px;
					height: 40px;
				}
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-supportContactUs {
		background-color: #ffffff;
		justify-content: start;

		.supportContactUs-section {
			&.section2 {
				padding-top: 30px;
				padding-bottom: 64px;
				margin-top: 258px;

				h2 {
					font-size: 24px;
				}

				h6 {
					font-size: 14px;
				}
			}

			&.section3 {
				max-width: 328px;

				p {
					font-size: 16px;
				}

				h5 {
					font-size: 14px;
				}

				h6,
				.locationText {
					font-size: 12px;
				}
			}

			&.section4 {
				padding-top: 72px;

				h2 {
					font-size: 24px;
				}

				h6 {
					font-size: 14px;
				}
			}

			&.section5 {
				padding-top: 64px;
				padding-bottom: 112px;

				p {
					font-size: 10px;
				}

				h6 {
					font-size: 12px;
				}

				img {
					height: 32px !important;
				}
			}

			&.section6 {
				p {
					font-size: 24px;
				}

				h6 {
					font-size: 12px;
				}

				.btn {
					font-size: 12px;
					width: 104px;
					height: 36px;
					line-height: 24px;
				}

				.col-12 {
					max-width: 360px;
				}
			}

			&.section7 {
				min-height: 0;

				.moreInfo {
					max-width: 328px;
					background-color: #f2f2f2;
					border-radius: 30px;

					.row .col-12 .border-end {
						border-right-width: 0 !important;
						border-bottom: 1px solid var(--grey5);
						margin-inline: 32px;
					}

					p {
						font-size: 16px;
					}
					h6 {
						font-size: 12px;
						line-height: 14px;
					}

					img {
						height: 14px !important;
					}
				}

				.afterSalesService {
					.btn-primary {
						width: 216px;
						height: 40px;
					}
				}
			}
		}
	}
}
