#container-exploreUpdateChange {
	input,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
		font-size: 14px;
	}

	select {
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.btnCancel {
		&:hover {
			color: white;
		}
	}

	.manageFileWrapper {
		button:nth-of-type(1) {
			&:hover {
				color: white;
			}
		}

		.fileView {
			height: 32px;
		}

		.cancelFileBtn {
			cursor: pointer;
		}
	}

	.infoWrapper {
		.infoTop {
			span {
				cursor: pointer;
			}
		}
	}

	.imageSection {
		height: 100%;
		width: 100%;
		padding: 71px 193px 0px 30px;
	
		> div {
			//border: 1px solid var(--grey3);
			height: 100%;
			max-height: 180px;
			width: 100%;
			border-radius: 20px;
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			-ms-border-radius: 20px;
			-o-border-radius: 20px;
			overflow: hidden;
}	

		.imageCard {
			height: 100%;
			background-color: var(--grey2);
	
			img {
				width: 100%;
				height: 100%;
			}
		}		
	}

	.w-80 {
		width: 80% !important;
	}

	.guideline {
		font-size: 12px;
		color: var(--grey3)
	}
}
