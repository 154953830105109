.container-custom {
	max-width: 1280px;
	min-height: 70vh;
	margin-top: 80px;

	#section-sidebar {
		.accordion-item {
			color: var(--grey4);
			border: none;
			background-color: var(--background);

			.accordion-button {
				background-color: var(--background);
				box-shadow: none;

				&:not(.collapsed) {
					color: var(--primary);

					.circleIcon {
						background-color: var(--primary);
					}
				}

				.circleIcon {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: var(--grey4);
				}
			}

			.accordion-collapse.show .accordion-body {
				color: var(--grey4) !important;
				border-radius: 12px;
				cursor: pointer;

				&.active {
					background-color: #efefef;
					color: var(--primary) !important;
				}
			}

			a .accordion-collapse.show .accordion-body {
				&:hover {
					background-color: #efefef;
					color: var(--primary) !important;
				}
			}

			.accordion-collapse.show .accordion-body.manageView {
				cursor: auto;
			}

			.accordion-body {
				margin-left: 14px;
			}

			.manageView .header {
				padding: 16px 20px;
				cursor: pointer;
				color: var(--primary);
				// &:after {
				// 	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23106cd1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
				// 	transform: rotate(-180deg);
				// }
			}
		}
	}
}
