#container-userDrillItem {
	input,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}

	select {
		background-image: linear-gradient(
				45deg,
				transparent 50%,
				var(--grey4) 50%
			),
			linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
		background-position: calc(100% - 14px) 14px, calc(100% - 8px) 14px;
		background-size: 6px 6px, 6px 6px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.btnCancel {
		&:hover {
			color: white;
		}
	}

	.infoBottom {
		label ~ div {
			height: 32px;
			padding: 0px 8px;
		}
	}

	.btn-outline-danger:hover g g {
		path,
		rect {
			fill: #fff !important;
		}
	}
}

.copyDrillModal {
	input,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}

	.btnCancel:hover {
		color: white;
	}

	.modal-dialog-centered {
		width: 664px;
	}

	.modal-title {
		font-size: 20px;
		color: #1278e8;
		font-weight: bold;
	}

	.modal-body {
		padding: 24px 40px;
		min-height: 312px;
		// width: 800px;
	}

	.modal-footer {
		padding: 24px 40px;

		button {
			width: 120px;
		}
	}

	.btn-size-s {
		max-width: 88px;
	}
}

.insertDrillItemModal,
.editDrillItemModal {
	input,
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}
	.btnCancel:hover {
		color: white;
	}

	.modal-dialog-centered {
		width: 438px;
	}

	.modal-title {
		font-size: 20px;
		color: #1278e8;
		font-weight: bold;
	}

	.modal-body {
		padding: 24px 40px;
		min-height: 312px;
		// width: 800px;
	}

	.modal-footer {
		padding: 24px 0;

		button {
			width: 120px;
		}
	}

	.btn-size-s {
		max-width: 88px;
	}
}

.card-body {
	margin-top: auto;

	.card-avatar-outer {
		width: 96px;
		height: 96px;
		margin-bottom: 24px;

		.card-avatar-inner {
			width: 96px;
			height: 96px;

			.card-avatar {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
	}
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 664px;
	}
}
