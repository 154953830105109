#container-productVipSearch .articleBoxWithSideBox {
	min-height: 752px;

	.form-search {
		margin-top: 32px;

		.emailID-group,
		.phone-group,
		.change__search,
		.invalid-feedback {
			width: 440px;
		}

		.form-control,
		.form-select {
			height: 48px;
			color: #989898;
			border-color: #dadada;
			box-shadow: none;
			-webkit-box-shadow: none;
			font-size: 16px;
		}

		.MuiSvgIcon-root.search__btn {
			cursor: pointer;
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			color: #dadada;
		}

		.change__search {
			font-size: 14px;
			color: #76adff;
			margin-top: 12px;
			cursor: pointer;
		}
	}

	.cardBox {
		margin-top: 85px;

		.card-body {
			.card-avatar-outer {
				width: 96px;
				height: 96px;
				margin-bottom: 24px;

				.card-avatar-inner {
					width: 96px;
					height: 96px;

					.card-avatar {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}
			}

			.card-text {
				font-size: 14px;
				margin-bottom: 32px;
			}

			.section-footer {
				margin-bottom: 32px;

				button {
					min-width: 88px;
					font-size: 14px;
				}
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	.row-custom {
		padding: 0;

		#container-productVipSearch .articleBoxWithSideBox {
			min-height: 490px;
			padding: 76px 16px 16px 16px;
			margin-bottom: 32px;
			border: none;
			border-radius: 0 0 8px 8px;

			.containTitle {
				left: 16px;
				font-size: 16px;
			}

			.form-search {
				margin-top: 8px;
			}

			.cardBox {
				margin-top: 45px;
			}
		}
	}
}
