#container-pwdReset {
	.articleBoxWithSideBox {
		label {
			font-size: 14px;
		}

		#oldPWD,
		#newPWD,
		#confirmPWD {
			font-size: 16px;
			height: 48px;
		}

		p {
			font-size: 14px;
		}

		button {
			max-width: 216px;
			width: 100%;
			height: 48px;
			font-size: 14px;
		}
	}

	#btn-confirm {
		margin-top: 32px;
		max-width: 216px;
		width: 100%;
		height: 48px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 992px) {
	#container-pwdReset {
		.articleBoxWithSideBox {
			padding: 70px 16px 16px 16px;
			border: none;
			border-radius: 0 0 8px 8px;

			.containTitle {
				left: 16px;
				font-size: 16px;
			}
		}
	}
	#btn-confirm {
		margin-bottom: 48px;
	}
}
