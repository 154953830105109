#container-feedback {
	::-webkit-scrollbar {
		width: 12px;
		height: 0px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #eee;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #ccc;
		/*border-radius: 6px;*/
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #aaa;
	}

	.phoneOSBtn,
	.appVersionBtn,
	.phoneOSVersionBtn,
	.progressBtn,
	.appNameBtn,
	.categoryBtn {
		position: relative;
		color: #282828;
		cursor: pointer;

		&:hover path {
			color: #1278e8;
		}
	}

	.phoneOSContent,
	.appVersionContent,
	.phoneOSVersionContent,
	.progressContent,
	.appNameContent,
	.categoryContent {
		position: absolute;
		top: 32px;
		z-index: 5;
		border: 1px solid #dadada;
		min-width: 120px;
		width: max-content;

		li {
			color: #282828;

			&:hover {
				color: #1278e8;
				background-color: #ffa;
			}

			label {
				cursor: pointer;
			}
		}
	}

	.cardBox {
		height: 520px;

		.imgBox {
			height: 250px;
			cursor: pointer;

			&:hover {
				background-color: pink !important;
			}

			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.infoBox {
			height: 250px;

			.infoIssue {
				height: 165px;

				p {
					overflow: scroll;
					height: 100%;
				}
			}
		}
	}

	#modal-feedback {
		top: 0;
		background-color: rgba(0, 0, 0, 0.6);

		.modalBox {
			height: 90vh;
		}
	}
}

@media screen and (max-width: 768px) {
	#container-feedback .cardBox .infoBox {
		.infoIssue {
			height: 140px;
		}
	}
}

.modal__backdrop {
	color: #2e384d;
	background: rgba(0, 0, 0, 0.65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;

	.modal__container {
		background: #fff;
		border-radius: 5px;
		max-width: 100%;
		margin: 50px auto;
		padding: 24px;
		width: 800px;
	}

	.modalTitleBox {
		.modal__title {
			font-size: 18px;
			font-weight: 600;
		}
		.downloadImg {
			width: 24px;
		}
	}

	h6 {
		font-size: 0.875rem;
		font-weight: 300;
		margin-bottom: 12px;
	}

	.selectDisabled {
		font-size: 0.875rem;
		font-weight: 300;
		border: none;
		appearance: none;
		border: none;
		appearance: none;
		background-color: transparent;
	}

	.modalBtn {
		border: 0;
		border-radius: 5px;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		padding: 10px 15px;
		transition: transform 100ms ease-out;
	}

	.modalEditBtn {
		color: rgb(58, 209, 126);
		border: 1px solid rgb(58, 209, 126);

		&:hover {
			background: rgb(1, 151, 68);
			color: #fff;
			transform: scale(1.1);
		}
	}

	.modalSaveBtn {
		background: rgb(41, 97, 253);
		color: #fff;

		&:hover {
			background: rgb(86, 20, 240);
			transform: scale(1);
		}
	}

	.modalCloseBtn {
		background: #ff4e42;
		color: #fff;

		&:hover {
			background: #fa3200;
			transform: scale(1.1);
		}
	}

	.editable {
		border: 1px solid rgb(41, 97, 253) !important;

		&:disabled {
			background-color: #e9ecef;
			border: none !important;
		}
	}
}
