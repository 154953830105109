#container-productStat {
	h1 h2 p {
		margin: 0;
	}

	.row {
		margin-left: 0;
		margin-right: 0;

		* {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.wrapper {
		width: 100%;
		max-width: 1320px;
	}

	.productStat-section {
		width: 100%;
		&.section0 {
			display: none;
		}

		&.section1 {
			height: 568px;
			margin-bottom: 160px;

			#product1_img {
				margin-top: 32px;
				height: 100%;
				width: 100%;
				background: url('../../../../public/images/productCenter/productStat/section1-1_web.png')
					left/contain no-repeat;
			}

			h1 {
				font-size: 50px;
			}

			h2 {
				font-size: 36px;
				margin-top: 164px;
				margin-bottom: 72px;
			}

			.top {
				height: 160px;
			}

			.middle {
				height: 480px;
				text-align: left;

				.productImageCard {
					padding-left: 80px;
				}
			}

			.left {
				font-size: 24px;
				width: 272px;
				padding-left: 48px;
			}

			.bottom {
				height: 96px;
			}
		}

		&.section-info {
			margin: 44px 0 44px 0;

			.left {
				font-size: 24px;
				width: 272px;

				.textZone {
					padding-left: 48px;
				}
			}

			.right {
				padding-left: 80px;
				padding-top: 4px;

				width: 100%;
				max-width: 1048px;

				.right-1 {
					max-width: 120px;
					padding: 0;
				}
				.right-2 {
					margin-left: 16px;
					width: 680px;
				}
			}
		}

		&.section10 {
			margin-bottom: 150px;
			h2 {
				font-size: 36px;
			}

			h3 {
				font-size: 24px;
			}

			.smallWrapper {
				max-width: 1168px;
			}

			ul {
				list-style: none;
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-productStat {
		background-color: white;

		.wrapper {
			width: 90%;
		}

		.border-top {
			border-top: 0 !important;
		}

		.productStat-section {
			&.section0 {
				position: fixed;
				display: block;
				top: 48px;
				min-height: 48px;
				z-index: 88;
				border-bottom: 1px solid #dee2e6;

				.dropdown-item {
					padding-left: 1rem;
					padding-right: 1rem;
				}

				.section0Wrapper {
					max-width: 1222px;
					line-height: 48px;

					.dropdown-menu {
						min-width: 0;
						line-height: 16px;

						a:active {
							color: var(--grey5);
							background-color: transparent;
						}
					}

					h6,
					button,
					a {
						font-size: 12px;
					}
				}
			}

			&.section-info {
				flex-direction: column;
				margin: 0;
				margin-bottom: 40px;

				.left {
					font-size: 20px;
					width: 272px;
					width: 100%;
					border-bottom: 1px solid #dee2e6;
					margin-top: 16px;

					.textZone {
						padding-left: 16px;
						margin-bottom: 16px;
					}
				}

				.right {
					font-size: 12px;
					padding-left: 16px;
					margin-top: 32px;

					width: 100%;
					max-width: 1048px;

					.right-1 {
						max-width: 120px;
						padding: 0;
					}
					.right-2 {
						margin-left: 16px;
						width: 680px;
					}
				}
			}

			&.section1 {
				margin-top: 50px;
				margin-bottom: 24px;
				height: 420px;

				.top {
					height: 50px;
				}

				.middle {
					flex-direction: column;
					height: 360px;

					.left {
						width: 100%;
						padding-bottom: 16px;
						padding-left: 16px;
					}

					.productImageCard {
						padding: 0 24px;
					}
				}
				#product1_img {
					margin-top: 0;
					height: 100%;
					width: 100%;
					background: url('../../../../public/images/productCenter/productStat/section1-1_app.png')
						center/contain no-repeat;
				}
				h1 {
					margin-top: 40px;
					font-size: 24px;
				}

				h2 {
					font-size: 16px;
					margin-top: 0;
					margin-bottom: 96px;
				}
			}

			&.section10 {
				font-size: 12px;
				margin-bottom: 40px;
			}
		}
	}
}
