#header-app {
	min-height: 79px;
	z-index: 999;

	&:hover {
		background-color: #fff;

		.homepageNavColor {
			a {
				color: #282828 !important;
			}
		}

		// .personIcon {
		// 	color: #282828;
		// }

		// .personIcon > path{
		// 	color: #282828 !important;
		// }
	}

	#container-header {
		max-width: 1216px;
		line-height: 79px;
		width: 100%;
	}

	.homepageNavColor {
		a {
			color: #fff;
		}
	}
}

.head-logo {
	a img {
		// padding: 24px 0;
	}
}

.head-menu {
	ul li {
		a {
			color: #282828;
			// padding: 40px 0;
		}

		&:hover {
			a {
				color: #1278e8;
			}

			ul li {
				a {
					color: #282828;
					padding: 40px 0;

					span {
						border-bottom: 0px;
					}
				}

				&:hover a {
					color: #1278e8;

					span {
						border-bottom: 0px;
					}
				}
			}
		}
	}
}

.head-menu-second {
	top: 104px;
	width: 100%;
	height: 80px;
	line-height: 80px;
}

.head-menu-second-Bg {
	top: 79px;
	left: 0;
	height: 50px;
	z-index: 3;
	border-top: 1px solid var(--grey2);

	ul {
		max-width: 1216px;
		padding-left: 168px;
		align-items: center;
		height: 100%;
	}
}

.head-right {
	.btn-outline-primary:hover {
		color: white;
	}

	a img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		object-fit: cover;
		margin-right: 8px;
	}

	#language {
		background-color: transparent;
		border: 0;
		color: #282828;
		cursor: pointer;
		outline: none;
	}

	#personIconContainer {
		.personIcon {
			// color: #282828;
			cursor: pointer;

			&:hover path {
				color: #1278e8;
			}
		}

		.personIconBox {
			position: absolute;
			top: 72px;
			left: -140px;
			min-width: 175px;
			z-index: 5;
			border: 1px solid #dadada;
			line-height: normal;

			a {
				color: #282828;

				&:hover {
					color: #1278e8;
				}
			}
		}
	}

	// 使用者資訊
	.userInfoBox {
		display: none;
		text-align: center;
		padding-top: 16px;
		padding-bottom: 16px;

		img {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			object-fit: cover;
		}

		h5 {
			margin-top: 8px;
			margin-bottom: 0;
			color: #000;
		}
	}
}
/* lists */
.list-auto,
.list-auto li {
	width: auto;
	float: left;
	display: block;
	position: relative;
	margin: 0;
}

.item-li {
	// padding-top: 30px;
	a {
		color: #282828;
		padding: 40px 0;

		span {
			border-bottom: 0px;
		}
	}

	.navFakeLink {
		cursor: pointer;
	}

	&:hover a,
	&:hover .navFakeLink {
		color: #1278e8;

		span {
			border-bottom: 0px;
		}
	}
}

.item-link {
	cursor: pointer;
}

.active-btn {
	color: #1278e8;
	border-bottom: 3px solid #1278e8;
	border-width: 3px;
}

// .border-bottom {
// 	border-bottom: 3px solid #1278e8 !important;
// 	margin-top: 4px;
// }

// navbar hamburger button
input#control-nav {
	visibility: hidden;
	position: absolute;
	left: -9999px;
	opacity: 0;
}

#language {
	border: 0;
	padding: 0 8px;
}

// rwd-navbar 搭配.rwd-navbar-transition淡入淡出
.rwd-navbar {
	z-index: 99;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #f8f8f8;

	visibility: hidden;
	opacity: 0;
	transition: all 0.5s ease;
}

@media screen and (max-width: 992px) {
	// navbar
	#header-app {
		border-bottom: 1px solid #dee2e6;
		height: 48px;
		min-height: 48px;
		// background-color: #fff !important;
	}

	.rwdNavBg {
		// background-color: #fff !important;
	}

	#header-app:hover {
		background-color: transparent;
	}

	.head-logo {
		a img {
			height: 28px;
			margin-bottom: 4px;
		}
	}

	#header-app #container-header {
		justify-content: center;
		line-height: 47px;
	}
	#header-app .head-right {
		position: absolute;
		right: 28px;
		top: 10px;
		z-index: 2;
	}

	#language {
		background-color: transparent;
		cursor: pointer;
	}

	.head-right #personIconContainer {
		.personIconBox {
			top: 32px;
		}
	}

	// accordion
	#nav-app .accordion-button {
		color: #282828;
		background: #f8f8f8;
		justify-content: start;
		font-size: 14px;
		padding: 0.5rem 1rem;
		pointer-events: auto;
		box-shadow: none;

		&::after {
			width: 0;
			margin: 0;
			color: #282828;
		}

		&:focus {
			box-shadow: none;
			color: #282828;
			background-color: #f8f8f8;
		}
	}

	#nav-app .accordion-body {
		padding: 0;
		background-color: #f8f8f8;
		ul li {
			margin-bottom: 10px;
			padding-left: 20px;
			background-color: #f8f8f8;
			a,
			span {
				display: block;
				width: 100%;
				font-size: 12px;
				color: #989898;
				cursor: pointer;
			}
		}
	}

	#nav-app .accordion-item {
		border: 0;
		background-color: #f8f8f8;
	}

	// navbar hamburger button
	#header-app .control-nav {
		// 	/* label icon */
		position: absolute;
		left: 28px;
		top: 10px;
		z-index: 2;
		cursor: pointer;
	}

	/* checked nav */
	input#control-nav {
		display: block;
	}

	// input#control-nav:focus ~ .control-nav {
	// 	border-color: #000;
	// 	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.3);
	// }

	.rwd-navbar-transition {
		visibility: visible;
		opacity: 1;
		top: 0;
	}

	input#control-nav:focus ~ .control-nav:before {
		background: #000;
	}

	.rwd-wrapper {
		margin-top: 60px;
	}

	.box-border {
		border: 1px solid #dadada;
		margin: 1rem;
	}

	.lang-box {
		color: #707070;
	}

	.nav-right-btn {
		position: absolute;
		right: 28px;
		top: 40px;
		z-index: 2;
		cursor: pointer;
	}

	.rwd-nav-appDownload-btn {
		.btn {
			font-size: 12px;
			&:hover {
				color: white;
			}
		}
	}

	.rwd-nav-lang {
		svg {
			font-size: 20px;
		}

		#language {
			font-size: 14px;
		}
	}

	// '登录 / 注册'
	.rwd-nav-footer {
		cursor: pointer;

		&:hover {
			position: relative;
		}

		.personIcon {
			font-size: 20px;
			top: 16px;
		}

		&:hover .personIcon {
			color: #1278e8;
			position: absolute;
			top: 12px;
			transition: top 0.3s ease-out;
			-webkit-transition: top 0.3s ease-out;
			-moz-transition: top 0.3s ease-out;
			-ms-transition: top 0.3s ease-out;
			-o-transition: top 0.3s ease-out;
		}

		#rwd-nav-footer-span {
			padding-left: 0.5rem;
			font-size: 14px;
		}

		&:hover #rwd-nav-footer-span {
			margin-left: 20px;
		}
	}

	.rwd-nav-footer-login {
		cursor: auto;

		.personIcon {
			font-size: 20px;
			margin-bottom: 0 !important;
		}

		#rwd-nav-footer-span {
			padding-left: 0.5rem;
			font-size: 14px;
		}
	}

	.rwd-nav-page {
		padding: 8px 16px;
		cursor: pointer;
		font-size: 14px;
	}
}
