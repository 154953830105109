#container-supportDownloadCenter {
	.supportDownloadCenter-section {
		&.section2 {
			min-height: auto;
			padding-block: 56px;
			margin-top: 120px;

			h2 {
				font-size: 36px;
			}

			h6 {
				font-size: 16px;
				max-width: 1168px;
			}
		}

		&.section3 {
			max-width: 1096px;

			.manualItem {
				border-radius: 30px;
			}

			h2 {
				font-size: 20px;
			}

			h6 {
				font-size: 14px;
			}

			.btn {
				font-size: 14px;
				width: 104px;
				height: 48px;
				line-height: 36px;
			}
		}

		&.section4 {
			min-height: auto;
			padding-top: 48px;
			margin-top: 192px;

			h2 {
				font-size: 36px;
			}

			h6 {
				font-size: 16px;
				max-width: 1168px;
			}
		}

		&.section5 {
			max-width: 1096px;
			border-radius: 30px;

			p {
				font-size: 24px;
			}

			h5 {
				font-size: 20px;
			}
		}

		&.section6 {
			.btn {
				height: 48px;
				line-height: 36px;
			}

			margin-bottom: 168px;
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-supportDownloadCenter {
		.supportDownloadCenter-section {
			&.section2 {
				padding-top: 30px;
				padding-bottom: 64px;
				margin-top: 258px;

				h2 {
					font-size: 24px;
				}

				h6 {
					font-size: 14px;
				}
			}

			&.section3 {
				.manualWrapper {
					width: 312px;
				}
			}

			&.section4 {
				padding-top: 36px;
				margin-top: 36px;

				h2 {
					font-size: 24px;
				}

				h6 {
					font-size: 14px;
				}
			}

			&.section5 {
				max-width: 312px;

				h5 {
					font-size: 16px;
				}
			}

			&.section6 {
				margin-bottom: 0;

				p {
					font-size: 24px;
				}

				h6 {
					font-size: 12px;
				}

				.btn {
					font-size: 12px;
					width: 104px;
					height: 36px;
					line-height: 24px;
				}

				.col-12 {
					max-width: 360px;
				}
			}
		}
	}
}
