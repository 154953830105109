.notFoundPageWrapper {
	margin-top: 80px;
	min-height: calc(100vh - 256px);

	.section1 {
		.imgWrapper {
			width: 340px;
			height: 340px;

			img {
				width: 150px;
			}
		}
	}

	.section2 {
		bottom: -80px;

		h1 {
			font-size: 30px;
		}

		h6 {
			font-size: 14px;
		}
	}

	.section3 .btn {
		width: 136px;
		height: 48px;
		line-height: 32px;
	}
}

@media only screen and (max-width: 992px) {
	.notFoundPageWrapper {
		margin-top: 48px;

		.section1 {
			.imgWrapper {
				width: 296px;
				height: 296px;

				img {
					width: 136px;
				}
			}
		}

		.section2 {
			bottom: -58px;

			h1 {
				font-size: 20px;
			}

			h6 {
				font-size: 12px;
			}
		}

		.section3 .btn {
			width: 112px;
			height: 40px;
			line-height: 28px;
			font-size: 14px;
		}
	}
}
