#container-productRegister .articleBoxWithSideBox {
	padding: 40px 80px;

	.registerProductTitle {
		font-size: 36px;
		margin-bottom: 16px;
	}

	h2 {
		font-size: 16px;
	}

	h6 {
		line-height: 48px;
	}

	#serialNo,
	#purchaseDate,
	#store {
		font-size: 16px;
		height: 48px;
	}

	label,
	a {
		font-size: 14px;
	}
}

/*    -- dialog樣式修改 --*/
.serialNoModal {
	.modal-header {
		border-bottom: 0px;
	}

	.modal-body {
		padding: 0% 15%;
	}

	.imgDiv .serialNoCheckImg {
		max-height: 500px;
		max-width: 100%;
	}
}

@media only screen and (max-width: 992px) {
	.row-custom {
		padding: 0;

		#container-productRegister .articleBoxWithSideBox {
			padding: 32px 16px;
			margin-bottom: 32px;
			border: none;
			border-radius: 0 0 8px 8px;

			.registerProductTitle {
				font-size: 24px;
				margin-bottom: 24px;
			}

			h2,
			ol {
				font-size: 12px;
			}
		}
	}
}
