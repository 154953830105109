#container-homepage {
	min-height: calc(100vh - 105px);

	a {
		color: #282828;

		&:hover {
			color: #282828;
		}
	}

	// carousel
	.carousel-section {
		.carousel-indicators {
			justify-content: start;
			margin: 0 10% 3% 10%;

			button {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				margin: 0 16px;
				background-color: #989898;
				opacity: 0.5;
			}

			button.active {
				background-color: #989898;
				opacity: 1;
			}
		}

		.carousel-inner .carousel-item {
			img {
				height: 51vw;
				width: 100vw;
				object-fit: cover;
			}

			.carousel-caption {
				position: absolute;
				top: 49%;
				left: 12%;
				padding-top: 1.25rem;
				padding-bottom: 1.25rem;
				text-align: left;
				color: #282828;

				h1 {
					font-size: 50px;
				}

				h2 {
					font-size: 36px;
				}
			}
		}

		// 換頁箭頭
		.carousel-control-prev,
		.carousel-control-next {
			.carousel-control-prev-icon,
			.carousel-control-next-icon {
				width: 1rem;
				height: 1rem;
			}

			.visually-hidden {
				z-index: -1;
				width: 55px !important;
				height: 55px !important;
				border-radius: 50%;
				background-color: #fff;
				clip: rect(auto, auto, auto, auto) !important;
				line-height: 55px;
			}
		}

		// .carousel-control-next-icon {
		// 	&:before {
		// 		content: '';
		// 		position: absolute;
		// 		width: 64px;
		// 		height: 64px;
		// 		border-radius: 50%;
		// 		background-color: #ffffff;
		// 		top: -50%;
		// 		left: -50%;
		// 	}
		// }
	}

	// articleCard
	.articleCard-section {
		padding: 176px 72px;

		.card a {
			&:hover {
				img {
					transition: all 0.3s ease-out;
					transform: scale(1.1, 1.1);
				}
			}

			.imgBox {
				height: 304px;
				overflow: hidden;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.card-body {
				padding: 24px 32px 56px 32px;

				.card-title {
					font-size: 32px;
				}

				.card-text {
					font-size: 14px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	#container-homepage {
		.articleCard-section {
			padding: 72px;
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-homepage {
		background-color: #ffffff;
		justify-content: start;

		.carousel-section {
			.carousel-indicators {
				width: 50%;
				margin: 0 auto 15% auto;
				justify-content: space-evenly;
				border-radius: 24px;
				background-color: rgba(255, 255, 255, 0.8);
			}

			.carousel-inner .carousel-item {
				img {
					height: 100vh;
					object-fit: cover;
				}

				.carousel-caption {
					position: absolute;
					top: 88px;
					padding-top: 0.75rem;
					padding-bottom: 1rem;
					text-align: center;
					color: #282828;

					h1,
					h2 {
						font-size: 24px;
					}

					p {
						font-size: 12px;
					}

					.carouselBtnWrap {
						gap: 8px !important;
					}
				}
			}
		}

		.articleCard-section {
			padding: 16px;

			.card a {
				.imgBox {
					height: 176px;
				}

				.card-body {
					padding: 16px 24px 38px 24px;

					.card-title {
						font-size: 20px;
					}

					.card-text {
						font-size: 12px;
					}
				}
			}
		}
	}
}
