#container-about {
	h1 h2 p {
		margin: 0;
	}

	.row {
		margin-left: 0;
		margin-right: 0;

		* {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.wrapper {
		width: 100%;
		max-width: 1320px;
	}

	.smallWrapper {
		width: 100%;
		max-width: 928px;
	}

	.about-section {
		width: 100%;
		&.section0 {
			display: none;
		}

		&.section1 {
			height: calc(100vh + 80px);
			margin-bottom: 80px;

			#section1-1_img {
				height: 100%;
				width: 100%;
				background: url('../../../public/images/about/section1-1_web.png')
					center/cover no-repeat;
			}

			.banner_title {
				// 其中一個水平垂直置中方法
				position: absolute;
				top: 260px; // 這次沒有垂直置中, 指定 240px
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.switchingPostionBar {
				width: 100%;
				height: 80px;
				position: absolute;
				bottom: 0;
				text-align: start;
				border-bottom: 1px solid var(--grey3);
				font-size: 16px;
			}

			h1 {
				font-size: 64px;
			}

			h2 {
				font-size: 36px;
				margin-top: 164px;
				margin-bottom: 72px;
			}

			.top {
				height: 96px;
			}

			.middle {
				height: 480px;
				text-align: left;

				.productImageCard {
					padding-left: 80px;
				}
			}

			.left {
				font-size: 24px;
				width: 272px;
				padding-left: 48px;
			}

			.bottom {
				height: 96px;
			}
		}

		&.section2 {
			margin-top: 36px;
			margin-bottom: 60px;

			a {
				color: var(--grey5);
				&:hover {
					color: var(--primary);
				}
			}

			.active {
				color: var(--primary);
			}

			div {
				font-size: 20px;
			}
		}

		&.section3 {
			height: 440px;
			margin-top: 36px;
			margin-bottom: 60px;

			h1 {
				font-size: 36px;
				margin-bottom: 24px;
			}

			p {
				font-size: 20px;
			}

			#section3-1_img {
				height: 100%;
				width: 100%;
				background: url('../../../public/images/about/section3-1_web.png')
					center/cover no-repeat;
			}

			.banner_title {
				position: absolute;
				top: 20%;
				left: 20%;
			}
		}

		&.section4 {
			margin-top: 36px;
			margin-bottom: 60px;

			div {
				font-size: 20px;
			}
		}

		&.section5 {
			margin-top: 36px;
			margin-bottom: 60px;

			.btn-size-customised {
				max-width: 160px;
				width: 100%;
				height: 48px;
				font-size: 14px;
			}
		}

		&.section6 {
			margin-top: 36px;
			margin-bottom: 60px;
			height: 424px;

			.left {
				width: 100%;
				max-width: 424px;
				height: 104px;
				border-bottom: 2px solid #3366ff;
				text-align: end;
				z-index: 87;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				-moz-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				-o-transform: translate(0, -50%);
			}

			.right {
				height: 424px;
				width: 100%;
				max-width: 1016px;
				background-color: var(--background);
				position: absolute;
				top: 0;
				left: 23%;

				.wordArea {
					width: 100%;
					max-width: 1016px;
					height: 100%;
					position: relative;
				}

				.word {
					font-size: 20px;
					max-width: 736px;
					position: absolute;
					top: 200px;
					right: 72px;
				}
			}

			.frontWord {
				font-size: 36px;
				width: 100%;
				margin-right: 8px;

				position: absolute;
				top: 38px;
				right: 24px;
			}

			.backWord {
				font-size: 50px;
				width: 100%;
				font-weight: bold;
				color: var(--grey2);

				position: absolute;
				top: 0;
				right: 16px;
			}
		}

		&.section7 {
			margin-top: 36px;
			margin-bottom: 60px;
			height: 424px;

			.left {
				width: 100%;
				max-width: 424px;
				height: 104px;
				border-bottom: 2px solid #3366ff;
				text-align: start;
				z-index: 87;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				-moz-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				-o-transform: translate(0, -50%);
			}

			.right {
				height: 424px;
				width: 100%;
				max-width: 1016px;
				background-color: var(--background);
				position: absolute;
				top: 0;
				right: 23%;

				.wordArea {
					width: 100%;
					max-width: 1016px;
					height: 100%;
					position: relative;
				}

				.word {
					font-size: 20px;
					max-width: 736px;
					position: absolute;
					top: 200px;
					left: 72px;
				}
			}

			.frontWord {
				font-size: 36px;
				width: 100%;
				margin-left: 8px;

				position: absolute;
				top: 38px;
				left: 24px;
			}

			.backWord {
				font-size: 50px;
				width: 100%;
				font-weight: bold;
				color: var(--grey2);

				position: absolute;
				top: 0;
				left: 16px;
			}
		}

		&.section8 {
			margin-top: 36px;
			margin-bottom: 60px;
			height: 424px;

			.left {
				width: 100%;
				max-width: 424px;
				height: 104px;
				border-bottom: 2px solid #3366ff;
				text-align: end;
				z-index: 87;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				-moz-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				-o-transform: translate(0, -50%);
			}

			.right {
				height: 424px;
				width: 100%;
				max-width: 1016px;
				background-color: var(--background);
				position: absolute;
				top: 0;
				left: 23%;

				.wordArea {
					width: 100%;
					max-width: 1016px;
					height: 100%;
					position: relative;
				}

				.word {
					font-size: 20px;
					max-width: 736px;
					position: absolute;
					top: 200px;
					right: 72px;
				}
			}

			.frontWord {
				font-size: 36px;
				width: 100%;
				margin-right: 8px;

				position: absolute;
				top: 38px;
				right: 24px;
			}

			.backWord {
				font-size: 50px;
				width: 100%;
				font-weight: bold;
				color: var(--grey2);

				position: absolute;
				top: 0;
				right: 16px;
			}
		}

		&.section9 {
			margin-top: 36px;
			margin-bottom: 60px;
			height: 424px;

			.left {
				width: 100%;
				max-width: 424px;
				height: 104px;
				border-bottom: 2px solid #3366ff;
				text-align: start;
				z-index: 87;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				-moz-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				-o-transform: translate(0, -50%);
			}

			.right {
				height: 424px;
				width: 100%;
				max-width: 1016px;
				background-color: var(--background);
				position: absolute;
				top: 0;
				right: 23%;

				.wordArea {
					width: 100%;
					max-width: 1016px;
					height: 100%;
					position: relative;
				}

				.word {
					font-size: 20px;
					max-width: 736px;
					position: absolute;
					top: 200px;
					left: 72px;
				}
			}

			.frontWord {
				font-size: 36px;
				width: 100%;
				margin-left: 8px;

				position: absolute;
				top: 38px;
				left: 24px;
			}

			.backWord {
				font-size: 50px;
				width: 100%;
				font-weight: bold;
				color: var(--grey2);

				position: absolute;
				top: 0;
				left: 16px;
			}
		}

		&.section10 {
			margin-top: 36px;
			margin-bottom: 44px;
			height: 440px;

			h1 {
				font-size: 36px;
				margin-bottom: 24px;
			}

			p {
				font-size: 20px;
			}

			#section3-1_img {
				height: 100%;
				width: 100%;
				background: url('../../../public/images/about/section10-1_web.png')
					center/cover no-repeat;
			}

			.banner_title {
				position: absolute;
				top: 20%;
				left: 20%;
			}
		}

		&.section11 {
			margin-top: 36px;
			margin-bottom: 208px;

			div {
				font-size: 20px;
			}
		}

		&.section-info {
			margin: 88px 0 88px 0;

			.left {
				font-size: 24px;
				width: 272px;

				.textZone {
					padding-left: 48px;
				}
			}

			.right {
				padding-left: 80px;
				padding-top: 4px;

				width: 100%;
				max-width: 1048px;

				.right-1 {
					max-width: 120px;
					padding: 0;
				}
				.right-2 {
					margin-left: 16px;
					width: 680px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1350px) {
	#container-about {
		.about-section {
			&.section6 {
				margin-top: 36px;
				margin-bottom: 100px;
				height: 424px;

				.left {
					width: 100%;
					max-width: 424px;
					height: 104px;
					border-bottom: 2px solid #3366ff;
					text-align: end;
					z-index: 87;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					-moz-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					-o-transform: translate(0, -50%);
				}

				.right {
					height: 424px;
					width: 100%;
					max-width: 1016px;
					background-color: var(--background);
					position: absolute;
					top: 0;
					left: 23%;

					.wordArea {
						width: 100%;
						max-width: 800px;
						height: 100%;
						position: relative;
					}

					.word {
						font-size: 20px;
						max-width: 480px;
						position: absolute;
						top: 200px;
						right: 72px;
					}
				}

				.frontWord {
					font-size: 36px;
					width: 100%;

					position: absolute;
					top: 38px;
					left: 0;
				}

				.backWord {
					font-size: 50px;
					width: 100%;
					font-weight: bold;
					color: var(--grey2);

					position: absolute;
					top: 0;
					left: 0;
				}
			}

			&.section8 {
				margin-top: 36px;
				margin-bottom: 100px;
				height: 424px;

				.left {
					width: 100%;
					max-width: 424px;
					height: 104px;
					border-bottom: 2px solid #3366ff;
					text-align: end;
					z-index: 87;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					-moz-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					-o-transform: translate(0, -50%);
				}

				.right {
					height: 424px;
					width: 100%;
					max-width: 1016px;
					background-color: var(--background);
					position: absolute;
					top: 0;
					left: 23%;

					.wordArea {
						width: 100%;
						max-width: 800px;
						height: 100%;
						position: relative;
					}

					.word {
						font-size: 20px;
						max-width: 480px;
						position: absolute;
						top: 200px;
						right: 72px;
					}
				}

				.frontWord {
					font-size: 36px;
					width: 100%;

					position: absolute;
					top: 38px;
					left: 0;
				}

				.backWord {
					font-size: 50px;
					width: 100%;
					font-weight: bold;
					color: var(--grey2);

					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-about {
		.wrapper,
		.smallWrapper {
			padding: 0 24px;
		}
		.fullWrapperRWD {
			padding: 0;
		}
		background-color: white;
		.border-top {
			border-top: 0 !important;
		}

		.about-section {
			&.section-info {
				flex-direction: column;
				margin: 0;
				margin-bottom: 40px;

				.left {
					font-size: 20px;
					width: 272px;
					width: 100%;
					border-bottom: 1px solid #dee2e6;

					.textZone {
						padding-left: 16px;
						margin-bottom: 16px;
					}
				}

				.right {
					font-size: 12px;
					padding-left: 16px;
					margin-top: 32px;

					width: 100%;
					max-width: 1048px;

					.right-1 {
						max-width: 120px;
						padding: 0;
					}
					.right-2 {
						margin-left: 16px;
						width: 680px;
					}
				}
			}

			&.section1 {
				height: 296px;
				margin-bottom: 36px;

				#section1-1_img {
					height: 296px;
					width: 100%;
					background: url('../../../public/images/about/section1-1_web.png')
						center/cover no-repeat;
				}

				.banner_title {
					//其中一個水平垂直置中方法position: absolute;
					top: 128px;
					//這次沒有垂直置中,指定240pxleft: 50%;
					transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					-moz-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					-o-transform: translate(-50%, -50%);
				}

				.switchingPostionBar {
					width: 100%;
					height: 48px;
					position: absolute;
					bottom: 0;
					text-align: start;
					border-bottom: 1px solid var(--grey3);
					font-size: 12px;
				}

				h1 {
					font-size: 24px;
				}

				h2 {
					font-size: 36px;
					margin-top: 164px;
					margin-bottom: 72px;
				}

				.top {
					height: 96px;
				}

				.middle {
					height: 480px;
					text-align: left;

					.productImageCard {
						padding-left: 80px;
					}
				}

				.left {
					font-size: 24px;
					width: 272px;
					padding-left: 48px;
				}

				.bottom {
					height: 96px;
				}
			}

			&.section2 {
				margin-top: 36px;
				margin-bottom: 60px;

				a {
					color: var(--grey5);
					&:hover {
						color: var(--primary);
					}
				}

				.active {
					color: var(--primary);
				}

				div {
					font-size: 14px;
				}
			}

			&.section3 {
				height: 552px;
				margin-top: 36px;
				margin-bottom: 12px;

				h1 {
					font-size: 24px;
					margin-bottom: 8px;
				}

				p {
					font-size: 14px;
				}

				#section3-1_img {
					height: 100%;
					width: 100%;
					background: url('../../../public/images/about/section3-1_app.jpg')
						center/cover no-repeat;
				}

				.banner_title {
					position: absolute;
					top: 72px;
					left: 50%;
					text-align: center;
					transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					-moz-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					-o-transform: translate(-50%, -50%);
				}
			}

			&.section4 {
				margin-top: 36px;
				margin-bottom: 32px;

				div {
					font-size: 14px;
				}
			}

			&.section5 {
				margin-top: 0;
				margin-bottom: 68px;

				.btn-size-customised {
					max-width: 112px;
					width: 100%;
					height: 40px;
					font-size: 12px;
				}
			}

			&.section6 {
				margin-top: 36px;
				margin-bottom: 52px;
				height: 360px;

				.left {
					width: 100%;
					max-width: 280px;
					height: 80px;
					border-bottom: 2px solid #3366ff;
					text-align: start;
					z-index: 87;
					position: absolute;
					top: -53px;
					left: 0;
					transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					-moz-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					-o-transform: translate(0, -50%);
				}

				.right {
					height: 360px;
					width: 100%;
					max-width: 1016px;
					background-color: var(--background);
					position: absolute;
					top: -36px;
					left: 8px;

					.wordArea {
						width: 100%;
						max-width: 800px;
						height: 100%;
						position: relative;
						display: flex;
						justify-content: center;
					}

					.word {
						font-size: 20px;
						max-width: 480px;
						position: static;
						margin: 0 32px;
						font-size: 14px;
						margin-top: 80px;
					}
				}

				.frontWord {
					font-size: 24px;
					width: 100%;

					position: absolute;
					top: 38px;
					left: 0;
				}

				.backWord {
					font-size: 24px;
					width: 100%;
					font-weight: bold;
					color: var(--grey2);

					position: absolute;
					top: 22px;
					left: 16px;
				}
			}

			&.section7 {
				margin-top: 36px;
				margin-bottom: 52px;
				height: 360px;

				.left {
					width: 100%;
					max-width: 280px;
					height: 80px;
					border-bottom: 2px solid #3366ff;
					text-align: start;
					z-index: 87;
					position: absolute;
					top: -53px;
					left: 0;
					transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					-moz-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					-o-transform: translate(0, -50%);
				}

				.right {
					height: 360px;
					width: 100%;
					max-width: 1016px;
					background-color: var(--background);
					position: absolute;
					top: -36px;
					left: 8px;

					.wordArea {
						width: 100%;
						max-width: 800px;
						height: 100%;
						position: relative;
						display: flex;
						justify-content: center;
					}

					.word {
						font-size: 20px;
						max-width: 480px;
						position: static;
						margin: 0 32px;
						font-size: 14px;
						margin-top: 80px;
					}
				}

				.frontWord {
					font-size: 24px;
					width: 100%;

					position: absolute;
					top: 38px;
					left: 0;
				}

				.backWord {
					font-size: 24px;
					width: 100%;
					font-weight: bold;
					color: var(--grey2);

					position: absolute;
					top: 22px;
					left: 16px;
				}
			}

			&.section8 {
				margin-top: 36px;
				margin-bottom: 52px;
				height: 360px;

				.left {
					width: 100%;
					max-width: 280px;
					height: 80px;

					text-align: start;
					z-index: 87;
					position: absolute;
					top: -53px;
					left: 0;
					transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					-moz-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					-o-transform: translate(0, -50%);
				}

				.right {
					height: 360px;
					width: 100%;
					max-width: 1016px;
					background-color: var(--background);
					position: absolute;
					top: -36px;
					left: 8px;

					.wordArea {
						width: 100%;
						max-width: 800px;
						height: 100%;
						position: relative;
						display: flex;
						justify-content: center;
					}

					.word {
						font-size: 20px;
						max-width: 480px;
						position: static;
						margin: 0 32px;
						font-size: 14px;
						margin-top: 80px;
					}
				}

				.frontWord {
					font-size: 24px;
					width: 100%;

					position: absolute;
					top: 38px;
					left: 0;
				}

				.backWord {
					font-size: 24px;
					width: 100%;
					font-weight: bold;
					color: var(--grey2);

					position: absolute;
					top: 22px;
					left: 16px;
				}
			}

			&.section9 {
				margin-top: 36px;
				margin-bottom: 60px;
				height: 360px;

				.left {
					width: 100%;
					max-width: 280px;
					height: 80px;
					border-bottom: 2px solid #3366ff;
					text-align: start;
					z-index: 87;
					position: absolute;
					top: -53px;
					left: 0;
					transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					-moz-transform: translate(0, -50%);
					-ms-transform: translate(0, -50%);
					-o-transform: translate(0, -50%);
				}

				.right {
					height: 360px;
					width: 100%;
					max-width: 1016px;
					background-color: var(--background);
					position: absolute;
					top: -36px;
					left: 8px;

					.wordArea {
						width: 100%;
						max-width: 800px;
						height: 100%;
						position: relative;
						display: flex;
						justify-content: center;
					}

					.word {
						font-size: 20px;
						max-width: 480px;
						position: static;
						margin: 0 32px;
						font-size: 14px;
						margin-top: 80px;
					}
				}

				.frontWord {
					font-size: 24px;
					width: 100%;

					position: absolute;
					top: 38px;
					left: 0;
				}

				.backWord {
					font-size: 24px;
					width: 100%;
					font-weight: bold;
					color: var(--grey2);

					position: absolute;
					top: 22px;
					left: 16px;
				}
			}

			&.section10 {
				font-size: 12px;
				margin-bottom: 12px;
				height: 552px;
				margin-top: 36px;

				h1 {
					font-size: 24px;
					margin-bottom: 8px;
				}

				p {
					font-size: 14px;
				}

				#section3-1_img {
					height: 100%;
					width: 100%;
					background: url('../../../public/images/about/section10-1_app.png')
						center/cover no-repeat;
				}

				.banner_title {
					position: absolute;
					top: 108px;
					left: 50%;
					text-align: center;
					transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					-moz-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					-o-transform: translate(-50%, -50%);
				}
			}

			&.section11 {
				margin-top: 36px;
				margin-bottom: 56px;

				div {
					font-size: 14px;
				}
			}
		}
	}
}
