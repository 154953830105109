#container-otaMainUpdateDetail {
	button,
	select {
		height: 32px;
		padding-block: 0;
		box-shadow: none;
	}

	.btnCancel {
		&:hover {
			color: white;
		}
	}
}
