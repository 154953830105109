#sidebar {
	height: calc(100% - 80px);
	padding: 24px 0px;
	border: 1px solid #dadada;
	border-radius: 30px;

	h2 {
		font-size: 16px;
	}

	ul li {
		& a {
			display: block;
			padding: 8px 0;
			color: #282828;
			text-decoration: none;

			&:hover {
				color: #76adff;
			}
		}

		&.active a {
			color: #1278e8;
		}
	}
}
