#container-physicalStore {
	h1 h2 p {
		margin: 0;
	}

	.wrapper {
		width: 70%;
	}
	.row {
		margin-left: 0;
		margin-right: 0;
	}

	hr {
		background-color: var(--grey2);
	}

	.afterSalesService {
		.btn-primary {
			width: 216px;
			height: 40px;
		}
	}

	.physicalStore-section {
		// min-height: calc(100vh - 105px);
		width: 100%;

		&.section0 {
			display: none;
		}

		&.section1 {
			margin-top: 105px;
			height: 568px;
			background: url('../../../public/images/physicalStore/section1_1.png')
				center/cover no-repeat;

			h1 {
				font-size: 50px;
			}

			h2 {
				font-size: 36px;
				margin-top: 164px;
				margin-bottom: 72px;
			}

			display: none;
		}

		&.section2 {
			margin-top: 78px;
			height: 50px;

			a {
				color: var(--grey5);
				&:hover {
					color: var(--primary);
				}
			}

			.onlineShopTitleSectionLink,
			.physicalStoreTitleSectionLink {
				&:hover {
					color: var(--primary);
				}
			}

			.buttonArea {
				display: flex;

				div {
					cursor: pointer;
				}
			}
		}

		&.section3 {
			margin: 104px 0 56px 0;

			// temporary adjust margin-top as 125% screenRatio
			margin-top: 32px;

			h2 {
				font-size: 36px;
			}
		}

		&.section4 {
			background-color: #f8f8f8;

			.onlineShopImage {
				width: 430px;
				height: 240px;
			}

			.onlineShopImage1 {
				background: url('../../../public/images/physicalStore/section4_1.png')
					center/cover no-repeat;
			}

			.onlineShopTitle {
				font-size: 24px;
			}
		}

		&.section5 {
			margin: 240px 0 32px 0;
			padding-top: 32px;

			h2 {
				font-size: 36px;
			}
			.textWrap {
				max-width: 660px;
			}
		}

		&.section6 {
			.physicalStoreCard > div {
				min-height: 168px;
				background-color: #f8f8f8;
			}

			.physicalStoreCard:not(:last-child) {
				margin-bottom: 1.5rem;
			}

			.physicalStore-name {
				width: 424px;
				height: 100%;
				display: flex;

				.cardInner {
					text-align: start;
					margin-top: 40px;
					margin-left: 24px;
				}
			}

			.physicalStore-location {
				width: 584px;

				.cardInner {
					text-align: start;
					padding: 40px 20px;
				}

				.cardInner > div {
					margin-bottom: 18px;
				}
			}

			.physicalStore-image {
				width: 312px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		&.section7 {
			h2 {
				font-size: 36px;
			}

			h3 {
				font-size: 24px;
			}
			.smallWrapper {
				max-width: 1168px;
			}

			ul {
				list-style: none;
			}
		}

		&.section8 {
			background-color: #f2f2f2;
			border-radius: 30px;
			margin-bottom: 40px;

			a {
				color: var(--grey5);
			}

			div {
				font-size: 24px;
			}

			h6 {
				font-size: 14px;
			}

			span {
				cursor: pointer;
			}
		}

		&.section9 {
			h2 {
				font-size: 36px;
			}
			p {
				font-size: 24px;
				max-width: 1168px;
			}

			.btn-primary {
				width: 216px;
				height: 40px;
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	#container-physicalStore {
		background-color: white;

		.wrapper {
			width: 100%;
		}

		.physicalStore-section {
			&.section1 {
				margin-top: 48px;
				height: 30%;
				min-height: 200px;
				background: url('../../../public/images/physicalStore/section1_1.png')
					center/cover no-repeat;

				h1 {
					margin-top: 40px;
					font-size: 24px;
				}

				h2 {
					font-size: 16px;
					margin-top: 0;
					margin-bottom: 96px;
				}
			}

			&.section2 {
				margin-top: 48px;
				height: 48px;

				a {
					font-size: 12px;
				}
			}

			&.section3 {
				margin: 72px 0 16px 0;
			}

			&.section5 {
				margin: 72px 0 16px 0;

				h2 {
					font-size: 20px;
				}

				div {
					font-size: 12px;
				}

				.textWrap {
					max-width: 660px;
				}
			}

			&.section6 {
				flex-direction: column;

				.physicalStoreCard > div {
					min-height: 208px;
					max-height: 304px;
					background-color: #f8f8f8;
					flex-direction: column;
					overflow: hidden;
					font-size: 12px;
				}

				.physicalStoreCard:not(:last-child) {
					margin-bottom: 1.5rem;
				}

				.physicalStore-name {
					width: auto;
					font-size: 16px;

					.cardInner {
						text-align: start;
						padding: 24px 0 16px 16px;
						margin-top: 0;
					}
				}

				.physicalStore-location {
					width: auto;

					.cardInner {
						text-align: start;
						padding: 0 56px 32px 56px;
					}
					.cardInner div:last-child {
						margin-bottom: 0;
					}
				}

				.physicalStore-image {
					width: auto;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			&.section7 {
				font-size: 12px;
				.smallWrapper {
					max-width: 1168px;
				}

				ul {
					list-style: none;
				}
			}

			&.section8 {
				margin: auto;
				background-color: #f2f2f2;
				border-radius: 30px;
				margin-bottom: 40px;

				div {
					font-size: 16px;
				}

				span {
					font-size: 12px;
					cursor: pointer;
				}

				.border-end {
					border-right-width: 0 !important;
					border-bottom: 1px solid var(--grey5);
					margin-inline: 32px;
				}

				max-width: 328px;
			}
		}
	}
}
