#container-appUpdate {
	.searchWrapper {
		button:nth-of-type(1) {
			&:hover {
				color: white;
			}
		}
	}

	.searchTableWrapper {
		.searchTableTop {
			.tabBtn {
				border-bottom: 1px solid var(--grey1);

				&:hover {
					color: var(--primary);
					cursor: pointer;
					border-bottom: 1px solid var(--primary);
				}
			}

			.tabBtn.active {
				color: var(--primary);
				border-bottom: 1px solid var(--primary);
			}

			button a {
				color: white;
			}

			span {
				cursor: pointer;
			}
		}

		.searchTableBottom {
			tr:hover {
				background-color: #ecf4fd;
				--bs-table-hover-bg: rgba(0, 0, 0, 0);
				cursor: pointer;
			}
		}
	}
}
