#container-register {
	min-height: calc(100vh - 176px);
	margin-top: 80px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.articleBox {
		min-height: 576 * 0.85px;
		margin-bottom: 48 * 0.85px;
	}

	#email,
	#fullname,
	#countryCode,
	#phoneCode,
	#password,
	#confirmPWD,
	#captchaInput-info,
	#verificationCode {
		font-size: 16 * 0.85px;
		height: 48 * 0.85px;
	}

	.registerPageShow {
		display: block;
	}

	.registerPageHidden {
		display: none;
	}

	.btnBox {
		margin-bottom: 8 * 0.85px;
	}

	.btn-color-white {
		background: #dadada;
	}

	.form-control-lg,
	.btn-color-main {
		font-size: 16 * 0.85px;
	}
	/* 限制框 */
	#limitBox {
		max-width: 312 * 0.85px;
		margin: auto;
	}

	/* Page 1 */
	.registerHidden {
		display: none;
	}

	.refreshBtn {
		height: 48 * 0.85px;
	}

	#agreeBtnBox {
		width: 312 * 0.85px;
		margin: 32px auto 0 auto;
	}

	#registerAgreeBtn {
		width: 312 * 0.85px;
		height: 48 * 0.85px;
	}

	/* Page 2 */
	#registerPage_2 {
		#resendVerifyBtn {
			width: 100%;
			font-size: 14 * 0.85px;
			height: 48 * 0.85px;
		}

		#promptMessageBox {
			margin-top: 40 * 0.85px;
			margin-bottom: 85 * 0.85px;
		}

		.btnBox .btn {
			height: 48 * 0.85px;
		}
	}
	/* Page 3 */
	#registerPage_3 {
		h1 {
			font-size: 36 * 0.85px;
			margin-bottom: 32 * 0.85px;
		}

		.btnBox {
			margin-top: 170 * 0.85px;
		}
	}

	/* 步驟標籤 */
	#section-step {
		max-width: 520 * 0.85px;
		width: 100%;
		margin-top: 64 * 0.85px;
		margin-bottom: 24 * 0.85px;
	}

	#section-step .row {
		margin-right: -8 * 0.85px;
		margin-left: -8 * 0.85px;
	}

	.stepTitle {
		color: #282828;
	}

	.circleIcon {
		background: #dadada;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #ffffff;
		display: inline-block;
		font-weight: bold;
		line-height: 1.6em;
		margin-right: 8 * 0.85px;
		text-align: center;
		width: 1.6em;
	}

	.currentStep {
		color: #282828;
	}

	.currentStep .circleIcon {
		background: #008eda;
	}
}

@media only screen and (max-width: 992px) {
	#container-register {
		background-color: #ffffff;
		justify-content: start;
		margin-top: 48px;

		#section-step {
			margin-top: 0;
			margin-bottom: 20px;
		}

		.pageTitle-mobile {
			margin-top: 32px;
			margin-bottom: 20px;
			font-size: 24 * 0.85px;
		}

		.articleBox {
			min-height: calc(100vh - 420px);
			padding: 16px;
			margin: 8px;
			border: none;
			border-radius: 8px;

			#registerPage_2 {
				#promptMessageBox {
					margin-top: 32px;
					margin-bottom: 80px;
				}
			}

			/* Page 3 */
			#registerPage_3 {
				margin-top: 80px;

				.btnBox {
					margin-top: 70px;
				}
			}
		}
	}
}

/* ---待改--- */

.invalid-feedback {
	display: block;
}
