#container-baseInfo {
	.articleBoxWithSideBox {
		padding: 88px 0 56px 0;

		.form-group {
			max-width: 400px;
		}

		.form-select {
			font-size: 16px;
			height: 48px;
		}
	}

	.left-side-wrapper .form-group {
		margin: 0 auto;
	}

	#upper-info-section {
		.photo-wrapper {
			border-left: 1px solid #c6c8ca;
			#avatar-btn {
				height: 48px;
				width: 136px;
				line-height: 48px;
			}
		}

		.left-side-wrapper .form-group {
			margin: 0 auto;
		}
	}

	#middle-info-section {
		.left-wrapper .form-group {
			margin: 0 auto;
		}
	}

	#lower-info-section {
		.left-wrapper .form-group {
			margin: 0 auto;
		}
	}

	.limitBox {
		max-width: 700px;
		margin: 0 auto;

		.row-re {
			justify-content: space-around;
		}
	}

	#countryCodeBox {
		width: 106px;
	}

	#phoneCodeBox {
		width: calc(100% - 202px);
	}

	#emailBox {
		width: calc(100% - 96px);
	}

	.btnSBox button {
		width: 72px;
	}

	.modal-body #countryCode {
		width: 100%;
	}

	.modal-body #phoneCode {
		width: 100%;
	}

	/* 驗證碼(待合併) */
	#code {
		max-width: 158px;
	}

	#promptMessageBox {
		display: -webkit-box;
	}

	#promptMessageBox i {
		color: #00da49;
		margin-right: 8px;
	}

	#promptMessageBox p {
		max-width: 288px;
	}

	/* ------------- */
	.photoInfo {
		text-align: center;
	}

	.photoInfo p {
		color: #c6c8ca;
		text-align: left;
		max-width: 140px;
		margin: 12px auto 0 auto;
		font-size: 14px;
	}

	#userImage {
		display: none;
	}

	/* Modal */

	#successForm i {
		font-size: 90px;
		color: lightgreen;
	}

	input {
		font-size: 16px;
	}

	input#file {
		display: none;
	}

	.row-re {
		flex-direction: row-reverse;
	}

	.outer_container {
		margin: 0 auto;
	}

	.form-control-lg {
		font-size: 16px;
		height: 48px;
	}

	label {
		margin-top: 12px;
	}

	.id-info {
		padding: 8px;
		font-size: 14px;
		color: #8199ff;
	}

	@media only screen and (max-width: 992px) {
		.articleBoxWithSideBox {
			border: 0;
			padding: 88px 16px 56px 16px;
		}

		#upper-info-section {
			.photo-wrapper {
				border-left: 0;
			}
		}
	}
}

.modal__email {
	.modal-header {
		border-bottom: 1px solid #989898;
		.modal-title {
			font-size: 20px;
			font-weight: normal;
		}
		.btn-close {
			margin-right: 4px;
			background-color: #f8f8f8;
			border: 1px solid #dadada;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
		}
	}

	.modal-body {
		min-height: 136px;
	}

	.waiting__div {
		margin: 24px 0;
		font-size: 12px;
		white-space: pre-wrap;
		line-height: 0.75;
	}

	.limitBox-modal {
		max-width: 312px;

		margin: 0 auto;
		display: flex;
		align-items: center;
	}

	#modal-input-form {
		width: 100%;
	}

	.email-input-wrapper,
	.phone-input-wrapper,
	#modal-input-form {
		max-width: 312px;
		margin: 0 auto;
		label {
			font-size: 14px;
			color: #686868;
		}
		input,
		select {
			font-size: 16px;
			height: 48px;
		}
	}

	#phoneForm {
		align-items: flex-end;
	}

	#promptMessageBox {
		color: #8199ff;
	}

	.modal-footer {
		border-top: 1px solid #989898;
	}

	#verifyAccountBtn,
	#updateAccountBtn {
		font-size: 14px;
		width: 88px;
		height: 40px;
		&:hover {
			background-color: #686868;
			border-color: #707070;
		}
	}

	#resendVerifyBtn {
		width: 128px;
		height: 48px;
		font-size: 14px;
	}

	#cancelBtn {
		font-size: 14px;
		width: 88px;
		height: 40px;
		margin-right: 16px;
		border-color: #898989;
		&:hover {
			border-color: #282828;
		}
	}
}

.swal2-modal.swal2-loading {
	height: 496px;
	width: 496px;
}
