.supportTop-section {
	margin-top: 80px;
	background-image: url('../../../public/images/support/mainImg_2x.png');
	height: calc(100vh - 256px);
	min-height: 648 * 0.85px;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;

	h1 {
		font-size: 50 * 0.85px;
		margin-top: 136 * 0.85px;
		margin-bottom: 112 * 0.85px;
	}
	p {
		font-size: 36 * 0.85px;
	}

	.searchWrap {
		width: 50%;

		input {
			height: 64 * 0.85px;
			text-indent: 20 * 0.85px;
			font-size: 20 * 0.85px;
			outline: none;

			&::placeholder {
				color: var(--grey3);
			}
		}

		img {
			right: 32 * 0.85px;
			top: 20 * 0.85px;
			cursor: pointer;
		}
	}

	.pageBtnWrapper {
		max-width: 1096 * 0.85px;
		bottom: -100 * 0.85px;
		height: 200 * 0.85px;
		border-radius: 30 * 0.85px;
		left: 50%;
		transform: translateX(-50%);

		h2 {
			font-size: 24 * 0.85px;
		}

		a {
			color: var(--grey5);
		}

		.productRegisterBtn {
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 992px) {
	.supportTop-section {
		margin-top: 48px;
		background-image: url('../../../public/images/support/mainImg_2x.png');
		max-height: 232 * 0.85px;
		min-height: auto;
		background-size: cover;

		h1 {
			font-size: 24 * 0.85px;
			margin-top: 40 * 0.85px;
			margin-bottom: 8 * 0.85px;
		}
		p {
			font-size: 16 * 0.85px;
			margin-bottom: 32 * 0.85px;
		}

		.searchWrap {
			width: 80%;

			input {
				height: 40 * 0.85px;
				font-size: 16 * 0.85px;
			}

			img {
				right: 32 * 0.85px;
				top: 9 * 0.85px;
				cursor: pointer;
			}
		}

		.pageBtnWrapper {
			max-width: 312 * 0.85px;
			bottom: -200 * 0.85px;
			height: 248 * 0.85px;
			border-radius: 30 * 0.85px;
			left: 50%;
			transform: translateX(-50%);
			padding: 24 * 0.85px;

			h2 {
				font-size: 12 * 0.85px;
			}

			.wrapDiv {
				width: 100%;
			}

			a:nth-of-type(1),
			a:nth-of-type(2) {
				padding-bottom: 24 * 0.85px;
			}

			a:nth-of-type(3),
			.productRegisterBtn {
				padding-top: 24 * 0.85px;
			}
		}
	}
}
