#section-sidebar {
	width: 176px;
	height: 100%;
}

#section-main {
	/* max-width: 1008px; */
}

#col-main {
	width: 100%;
}

#col-sidebar {
}

.row-custom {
	display: -webkit-box;
	display: flex;
	/* flex-wrap: wrap; */
	margin-left: -16px;
	margin-right: -16px;
	padding: 72px 0;
}

.col-m {
	/* position: relative;
    width: 100%; */
	padding-right: 16px;
	padding-left: 16px;
}

/* 網頁容器 */
.container-custom {
	max-width: 1280px;
	width: 100%;
	padding-right: 32px;
	padding-left: 32px;
	margin-top: 80px;
	margin-right: auto;
	margin-left: auto;
}

@media (max-width: 992px) {
	.container-custom {
		padding-right: 8px;
		padding-left: 8px;
	}

	.row-custom {
		margin-right: -8px;
		margin-left: -8px;
	}

	.col-m {
		padding-right: 8px;
		padding-left: 8px;
	}

	#col-sidebar {
		display: none;
	}
}
